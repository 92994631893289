import * as React from 'react'
import { useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react'
import { useNavigate, UNSAFE_NavigationContext } from 'react-router-dom'
import { Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Box, TextField, IconButton, Typography, Autocomplete, Button, Checkbox } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { MaterialKey, MaterialObj, MaterialOptions, EquipmentType, EquipmentFrom } from '@constant/qrcode'
import {
  saveRound,
  getRoundById,
  getRoundTypes,
  getInstallationRoundDetail,
  downloadInstallationDetail,
  GetPropertyContacts,
  getBuildingInfo,
} from '@api'
import { useSelector } from 'react-redux'
import { History, Transition } from 'history'
import classnames from 'classnames'
import useStyles from './styles'
import SnackBar from '@component/SnackBar'
import Dialog from '@component/Modal/Dialog'
import { getCachePrintTemplates } from '@utils/qrUtil'
import EquipmentTable, { EquipmentsItem } from './EquipmentTable'
import Images from '@/assets/images'
import { generateDurationOption } from '@utils/DateUtil'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { InputAdornment } from '@mui/material'
import { WorkOrderTemplateITF } from '@component/Modal/AutoClosePMModal'

const language = 'en'
const showSkip1FA = process.env.REACT_APP_TYPE !== 'PRODUCTION'
const FrequencyOption: string[] = []
for (let index = 1; index < 101; index++) {
  FrequencyOption.push(String(index))
}
const isDEV = process.env.REACT_APP_TYPE === 'DEV'

export const PRINTKEY = [
  'InstallationNote',
  'Quantity',
  'Material',
  'Size',
  'Orientation',
  'Color',
  'PrintTemplate',
  'FileFormat',
]
export const INSTALLATION_KEY = ['KitTag']
const EstimatedTimeOption = generateDurationOption().map((v) => ({
  Name: v.value,
  DisplayText: { en: v.label, es: v.label },
}))

const getEngineeringRound = (option: any) => {
  const configData = [
    { name: 'QrName', title: 'Round Name*' },
    { name: 'StartText', title: 'Start Round Text' },
    { name: 'RoundType', title: 'Round Type*', isObj: true, option: option?.RoundType || [] },
    { name: 'Assigner', title: 'Assigned To', isObj: true, option: option?.Contacts || [] },
    {
      name: 'Frequency',
      title: 'Frequency*',
      isObj: true,
      option: [
        { DisplayText: { en: 'Ad-Hoc', es: 'Ad-Hoc' }, Name: 'AdHoc' },
        { DisplayText: { en: 'Recurring', es: 'Recurring' }, Name: 'Recurring' },
      ],
    },
    {
      name: 'Schedule',
      title: 'Schedule*',
      width: 300,
      option: FrequencyOption,
      frequencySpanOption: ['Day', 'Week', 'Month', 'Quarter', 'Year'],
    },
    { name: 'EstimatedTime', title: 'Estimated Time', option: EstimatedTimeOption, isObj: true, width: 220 },
    { name: 'LastInspectionDate', title: 'Last Inspection Date', width: 220 },
    { name: 'GeoEnable', title: 'Geo', isBoolean: true, width: 100 },
    { name: 'OfflineEnable', title: 'Offline Mode', isBoolean: true, width: 150 },
    { name: 'WorkLetter', title: 'Work Letter', isBoolean: true, width: 150 },
    { name: 'Skip1FA', title: 'Anonymous User', isBoolean: true },
    { name: 'InstallationNote', title: 'Installation Note', multiline: true },
    { name: 'Quantity', title: 'Quantity' },
    { name: 'Material', title: 'Material', option: MaterialOptions },
    { name: 'Size', title: 'Size', option: [] },
    { name: 'Orientation', title: 'Orientation', option: ['Landscape', 'Portrait', 'NA'] },
    { name: 'Color', title: 'Color', option: ['Light Gray', 'Light Blue', 'Dark Blue', 'Dark Gray', 'NA'] },
    {
      name: 'PrintTemplate',
      title: 'Print Template',
      option: option.RoundInspectionPrintTemplate || [],
      isObj: true,
      width: 200,
      isMax: true,
    },
    { name: 'FileFormat', title: 'File Format', option: ['png', 'svg', 'eps', 'pdf'] },
  ]
  const configEquipment = [
    { name: 'EquipmentName', title: 'Name', readOnly: true },
    { name: 'StopType', title: 'Stop Type*', readOnly: true },
    { name: 'EquipmentType', title: 'Type*', option: EquipmentType, readOnly: true },
    { name: 'Floor', title: 'Floor*', readOnly: true },
    { name: 'MechanicalSpace', title: 'Mechanical Space*', width: 200, readOnly: true },
    { name: 'ScanRequired', title: 'Scan Required', isBoolean: true, width: 160, singleChoice: 'TapRequired' },
    { name: 'TapRequired', title: 'Tap Required', isBoolean: true, width: 160, singleChoice: 'ScanRequired' },
    { name: 'PhotoRequired', title: 'Photo Required', isBoolean: true, width: 150 },
    { name: 'VideoRequired', title: 'Video Required', isBoolean: true, width: 150 },
    { name: 'AlertRequired', title: 'Alert', isBoolean: true, width: 100 },
    { name: 'Required', title: 'Required', isBoolean: true, width: 100 },
    { name: 'NewQr', title: 'Create new QR', isBoolean: true, width: 150 },
    { name: 'SignId', title: 'Sign ID' },
    { name: 'PageHeader', title: 'Page Headers', width: 240 },
    { name: 'InstallationNote', title: 'Installation Note', width: 200, readOnly: true },
    { name: 'Quantity', title: 'Quantity' },
    { name: 'Material', title: 'Material', option: MaterialOptions },
    { name: 'Size', title: 'Size', option: [] },
    { name: 'Orientation', title: 'Orientation', option: ['Landscape', 'Portrait', 'NA'] },
    { name: 'Color', title: 'Color', option: ['Light Gray', 'Light Blue', 'Dark Blue', 'Dark Gray', 'NA'] },
    {
      name: 'PrintTemplate',
      title: 'Print Template',
      option: option.EquipmentInspectionPrintTemplate || [],
      isObj: true,
      width: 200,
      isMax: true,
    },
    { name: 'FileFormat', title: 'File Format', option: ['png', 'svg', 'eps', 'pdf'] },
  ]

  const installationEquipment = [
    { name: 'SignId', title: 'Sign ID', readOnly: true },
    { name: 'QrName', title: 'QR Name', readOnly: true },
    { name: 'TypeDisplayText', title: 'QR Type', readOnly: true, isObj: true },
    { name: 'Floor', title: 'Floor', readOnly: true },
    { name: 'LocationDisplayText', title: 'Location', readOnly: true, isObj: true },
    { name: 'KitTag', title: 'Kit Tag', readOnly: true },
    { name: 'InstallationNote', title: 'Installation Note', width: 200, readOnly: true },
    { name: 'Quantity', title: 'Quantity', readOnly: true },
    { name: 'Material', title: 'Material', option: MaterialOptions, readOnly: true },
    { name: 'Size', title: 'Size', option: [], readOnly: true },
    { name: 'Orientation', title: 'Orientation', option: ['Landscape', 'Portrait', 'NA'], readOnly: true },
    {
      name: 'Color',
      title: 'Color',
      option: ['Light Gray', 'Light Blue', 'Dark Blue', 'Dark Gray', 'NA'],
      readOnly: true,
    },
    {
      name: 'PrintTemplate',
      title: 'Print Template',
      option: option.EquipmentInspectionPrintTemplate || [],
      isObj: true,
      width: 200,
      isMax: true,
      readOnly: true,
    },
    { name: 'FileFormat', title: 'File Format', option: ['png', 'svg', 'eps', 'pdf'], readOnly: true },
  ]
  const requiredMsg = configData
    .filter((v) => v.title.includes('*'))
    .map((item) => item.title)
    .join(',')

  const EquipmentErrMsg = configEquipment
    .filter((v) => v.title?.includes('*'))
    .map((item) => item.title)
    .join(',')
  return {
    configData,
    requiredMsg,
    configEquipment,
    EquipmentErrMsg,
    installationEquipment,
  }
}

interface DataItem {
  QrName: string
  RoundType: EquipmentFrom
  InstallationNote: string
  label?: Record<string, any>
  Assigner?: Record<string, any>
  SREnable: boolean
  SRDisabled: boolean
  LinkedPM: WorkOrderTemplateITF[]
  [k: string]: string | number | boolean | Record<string, any> | undefined
}
interface Props {
  id: string
  type?: EquipmentFrom
  saveCount: number
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const EngineeringBuilder = (props: Props) => {
  const { id: RoundId, saveCount, type } = props
  const isEdit = !!RoundId
  const classes = useStyles()
  const navigate = useNavigate()
  const navigator = useContext(UNSAFE_NavigationContext).navigator as unknown as History
  const { selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const [option, setOption] = useState({})
  const [data, setData] = useState<DataItem>({
    RoundType: isEdit && type ? type : EquipmentFrom.Engineering,
    QrName: '',
    Frequency: 'Recurring',
    Times: '',
    Period: '',
    EstimatedTime: '',
    LastInspectionDate: '',
    InstallationNote: '',
    QrType: 'RoundInspection',
    Quantity: '',
    State: '',
    Material: '3mm Sintra',
    Size: '5 × 8',
    Orientation: 'Landscape',
    Color: 'Light Gray',
    PrintTemplate: '',
    FileFormat: 'eps',
    LinkedPM: [],
    label: {
      EntityType: 'Mechanical Space',
    },
    Assigner: { UserId: '' },
    GeoEnable: false,
    OfflineEnable: false,
    SREnable: false,
    SRDisabled: false,
    AngusPropertyId: '',
    Skip1FA: false,
    StartText: 'Start a new round',
    WorkLetter: false,
  })
  const [Equipments, setEquipments] = useState<EquipmentsItem[]>([])
  const [action, setAction] = useState(false)
  const [actionClear, setActionClear] = useState(false)
  const { configData, configEquipment, installationEquipment, requiredMsg, EquipmentErrMsg } = useMemo(() => {
    return getEngineeringRound(option)
  }, [option])
  const ref = useRef({ isChanges: false, router: '', actionClear })
  const [savePrompt, setSavePrompt] = useState(0)
  const [showPrint, setShowPrint] = useState(false)
  const isInstallation = data.RoundType === EquipmentFrom.Installation
  const isAdHoc = data.Frequency === 'AdHoc'
  const isInspection = data.RoundType === EquipmentFrom.Inspection

  const { selectedLength, checkedEquipment } = useMemo(() => {
    let selectedEquipmentLength = 0
    const selectedLength = Equipments.reduce((pre, cur) => {
      let length = pre
      if (cur.checked) {
        selectedEquipmentLength++
        length++
      }
      length += cur.FormTemplateFields.filter((v) => v.checked).length
      return length
    }, 0)
    return {
      selectedLength,
      checkedEquipment: !!selectedEquipmentLength,
    }
  }, [Equipments, action])

  const showAction = useMemo(() => {
    const { actionClear: preActionClear } = ref.current
    ref.current.actionClear = actionClear
    if (preActionClear !== actionClear) return false
    return !!selectedLength
  }, [selectedLength, actionClear])

  const onDelete = () => {
    Dialog.show({
      title: checkedEquipment ? 'Delete Equipment' : 'Delete Question',
      desc: `Are you sure? ${
        checkedEquipment ? 'Any questions associated with this equipment will also be deleted.' : ''
      } This action cannot be undone.`,
      agreeText: 'Delete',
      onAgree: () => {
        for (let i = Equipments.length - 1; i >= 0; i--) {
          const { checked, FormTemplateFields, EquipmentId } = Equipments[i]
          if (checked) {
            const LinkedPM = (data.LinkedPM || []).filter((item) => item.EquipmentId !== EquipmentId)
            setData((pre) => {
              pre.LinkedPM = LinkedPM
              ref.current.isChanges = true
              return { ...pre }
            })
            Equipments.splice(i, 1)
            continue
          }

          for (let j = FormTemplateFields.length - 1; j >= 0; j--) {
            if (FormTemplateFields[j].checked) {
              FormTemplateFields.splice(j, 1)
            }
          }
        }
        setAction(!action)
      },
    })
  }

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {}>, type?: string, option?: any) => {
      const { name = type, value: newValue } = event.target as any
      const rowValue = option || newValue
      // @ts-ignore
      const value = typeof rowValue === 'string' ? rowValue : rowValue.Name
      // @ts-ignore
      const displayText =
        typeof rowValue === 'string' ? rowValue : rowValue.DisplayText?.[language] || rowValue?.FileName

      setData((pre) => {
        if (name === 'Quantity' && !/^[0-9]*$/.test(value)) {
          return pre
        }
        pre[name] = value
        if (!pre.label) pre.label = {}
        pre.label[name] = displayText
        const { Material } = pre
        if (name === 'Material') {
          pre['Size'] = MaterialObj[Material as MaterialKey]['default']
        }
        if (name === 'RoundType' && value === EquipmentFrom.Installation) {
          pre['Times'] = '1'
          pre['Period'] = 'Year'
          if (isDEV) {
            pre['Times'] = '10'
            pre['Period'] = 'Day'
          }
        }
        if (name === 'RoundType' && value === EquipmentFrom.Inspection) {
          pre['Times'] = '1'
        }
        if (name === 'Frequency' && value === 'AdHoc') {
          pre['Times'] = ''
          pre['Period'] = ''
        }
        if (name === 'Frequency' && value === 'Recurring' && pre.RoundType === EquipmentFrom.Inspection) {
          pre['Times'] = '1'
        }
        ref.current.isChanges = true
        return { ...pre }
      })
    },
    []
  )

  const handleDateChange = (date: Date | null) => {
    setData((pre) => {
      pre['LastInspectionDate'] = new Date(date as Date).getTime()
      ref.current.isChanges = true
      return { ...pre }
    })
  }

  const booleanChange = (name: string, value: boolean) => {
    setData((pre) => {
      pre[name] = value
      ref.current.isChanges = true
      return { ...pre }
    })
  }

  const onSave = useCallback(() => {
    setData((data) => {
      const { hasError, msg } = canSave(data, Equipments, requiredMsg, EquipmentErrMsg)
      if (hasError) {
        SnackBar({ msg, duration: 3000 })
      } else {
        saveRound({
          GroupId: selectedGroupId,
          BuildingId: selectedPropertyId,
          RoundId: RoundId || '',
          ...data,
          Stops: Equipments,
        }).then(() => {
          SnackBar({ msg: 'Saved successfully. Please refresh in a moment to see the result.', type: 'success' })
          const { router } = ref.current
          ref.current.isChanges = false
          if (router) {
            navigate(router)
          } else {
            navigate('/library/roundsEngine/0')
          }
        })
      }

      return data
    })
  }, [Equipments])

  const onDownload = () => {
    downloadInstallationDetail({ rId: RoundId }).then((res: any) => {
      window.open(res?.UploadUrl, '_blank')
    })
  }

  useEffect(() => {
    getCachePrintTemplates('RoundInspection', '').then((res: any) => {
      setOption((value) =>
        Object.assign({}, value, {
          RoundInspectionPrintTemplate: res.map((v) => ({ FileName: v?.FileName, Name: v?.Code })),
        })
      )
    })
    getCachePrintTemplates('EquipmentInspection', '').then((res: any) => {
      setOption((value) =>
        Object.assign({}, value, {
          EquipmentInspectionPrintTemplate: res.map((v) => ({ FileName: v?.FileName, Name: v?.Code })),
        })
      )
    })
    getRoundTypes().then((res: any) => {
      setOption((value) =>
        Object.assign({}, value, {
          RoundType: res.map((v) => {
            v.Name = v?.Type
            return v
          }),
        })
      )
    })
    GetPropertyContacts({
      filter: JSON.stringify({ bId: selectedPropertyId }),
      pagination: JSON.stringify({ page: 0, perPage: 10000 }),
    }).then((res: any) => {
      setOption((value) =>
        Object.assign({}, value, {
          Contacts: res.Contacts?.map((v) => {
            v.Name = v?.UserId
            v.DisplayText = v?.FullName
            return v
          }),
        })
      )
    })
    getBuildingInfo({ eId: selectedPropertyId }).then((res: any) => {
      setData((pre) => ({ ...pre, SRDisabled: !res?.DefaultTenantId, AngusPropertyId: res?.AngusPropertyId }))
    })
  }, [selectedPropertyId])

  useEffect(() => {
    if (RoundId) {
      getRoundById({ rId: RoundId }).then((res: any) => {
        !isInstallation && setEquipments(res.Stops)
        setData((pre) => ({ ...pre, ...res }))
      })
      if (isInstallation) {
        getInstallationRoundDetail({ rId: RoundId }).then((res: any) => {
          res?.StopQrCodes?.forEach((v) => {
            v.FormTemplateFields = []
            v.Material = v.Material || 'NA'
          })
          setEquipments(res.StopQrCodes || [])
        })
      }
    }
  }, [RoundId, isInstallation])

  useEffect(() => {
    if (saveCount || savePrompt) {
      onSave()
    }
  }, [saveCount, savePrompt])

  useEffect(() => {
    const unblock = navigator.block((tx: Transition) => {
      const pathname = tx.location.pathname
      if (ref.current.isChanges && !pathname.includes('roundsEngine')) {
        ref.current.router = pathname
        Dialog.show({
          title: 'Save Changes?',
          desc: 'Would you like to save your outstanding changes before navigating away?',
          cancelText: 'Discard Changes',
          agreeText: 'SAVE',
          onAgree: () => {
            setSavePrompt((v) => v + 1)
          },
          onCancel: () => {
            unblock()
            tx.retry()
            ref.current.isChanges = false
            navigate(ref.current.router)
          },
        })
      } else {
        unblock()
        tx.retry()
      }
    })
    return unblock
  }, [])

  useEffect(() => {
    if (isInstallation) {
      setShowPrint(true)
    }
  }, [isInstallation])

  return (
    <Box className={classes.paper}>
      <Box className={classes.title}>
        <span>{isEdit ? 'Editing Round' : 'Add New Round'}</span>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {isEdit && isInstallation && (
            <Button color="inherit" style={{ fontSize: 14, fontWeight: '600' }} onClick={onDownload}>
              Download CSV
            </Button>
          )}
          <span style={{ fontSize: 14 }}>
            Show Printing Details
            <Switch checked={showPrint} name={'checked'} onChange={(e) => setShowPrint(!showPrint)} />
          </span>
        </Box>
      </Box>
      <Box className={classes.action} style={{ display: showAction ? '' : 'none' }}>
        <IconButton onClick={() => setActionClear((v) => !v)} style={{ padding: 4, marginRight: 8 }}>
          <Clear className={classes.color} style={{ fontSize: 20 }} />
        </IconButton>
        <Typography style={{ flex: 1 }}>{selectedLength} item selected</Typography>

        {selectedLength && (
          <Box onClick={() => onDelete()} className={classes.actionIcon}>
            <img src={Images.iconDelete} style={{ marginRight: 6 }} alt="" />
            Delete
          </Box>
        )}
      </Box>

      <Box className={classnames(classes.container)}>
        <Box className={classes.absolute}>
          <Table className={classes.table}>
            <colgroup>
              {configData.map((v, i) => {
                if (isInstallation && v.name === 'Frequency') return null
                if ((isInstallation || isAdHoc) && v.name === 'Schedule') return null
                if (!showSkip1FA && v.name === 'Skip1FA') return null
                if (!isInspection && ['LastInspectionDate', 'WorkLetter'].includes(v.name)) return null
                return <col key={i} width={v.width || 182} />
              })}
            </colgroup>

            <TableHead className={classnames(classes.tableHead)}>
              <TableRow>
                {configData.map((v) => {
                  let title = v.title
                  if (!showPrint && PRINTKEY.includes(v.name)) {
                    title = ''
                  }
                  if (isInstallation && v.name === 'Frequency') return null
                  if ((isInstallation || isAdHoc) && v.name === 'Schedule') return null
                  if (!showSkip1FA && v.name === 'Skip1FA') return null
                  if (!isInspection && ['LastInspectionDate', 'WorkLetter'].includes(v.name)) return null
                  return (
                    <TableCell
                      key={v.title}
                      classes={{
                        root: classes.cell,
                      }}
                    >
                      {title}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={classnames(classes.tableRow)}>
                {configData.map((v, j) => {
                  const { name, option = [], isMax, isObj, multiline, isBoolean } = v
                  const { Material } = data
                  if (!showPrint && PRINTKEY.includes(name)) return null
                  if (isInstallation && name === 'Frequency') return null
                  if ((isInstallation || isAdHoc) && name === 'Schedule') return null
                  if (!isInspection && ['LastInspectionDate', 'WorkLetter'].includes(name)) return null

                  if (!showSkip1FA && v.name === 'Skip1FA') return null
                  let disabled = name === 'RoundType' && (isEdit || Equipments.length > 0)
                  if (name === 'Assigner') {
                    disabled = true
                  }
                  let options = option
                  if (name === 'Size') {
                    if (Material === 'NA') {
                      options = ['NA']
                    } else {
                      const key = Material as MaterialKey
                      options = MaterialObj[key]['option']
                    }
                  }

                  if (name === 'Schedule') {
                    return (
                      <TableCell key={j} classes={{ root: classnames(classes.cell, classes.frequencyCell) }}>
                        <Autocomplete
                          disableClearable
                          onChange={(e, value) => handleChange(e, 'Times', value)}
                          className={classnames(classes.textField, classes.frequencyTimes)}
                          options={options}
                          value={String(data['Times'] || '')}
                          getOptionLabel={(option: any) => option?.DisplayText?.en || option}
                          classes={{
                            inputRoot: classes.autocomplete,
                            popper: classes.frequencyOption,
                            listbox: classes.listbox,
                          }}
                          disabled={isInspection}
                          renderInput={(params) => <TextField {...params} name={name} variant="outlined" />}
                        />
                        <Box className={classes.frequencyText}>times per</Box>
                        <Autocomplete
                          disableClearable
                          onChange={(e, value) => handleChange(e, 'Period', value)}
                          className={classes.textField}
                          options={v.frequencySpanOption || []}
                          value={data['Period'] || ''}
                          getOptionLabel={(option: any) => option?.DisplayText?.en || option}
                          classes={{
                            inputRoot: classes.autocomplete,
                            popper: classes.frequencyOption,
                            listbox: classes.listbox,
                          }}
                          renderInput={(params) => <TextField {...params} name={'Period'} variant="outlined" />}
                        />
                      </TableCell>
                    )
                  }

                  // @ts-ignore
                  let value = isObj ? options.find((item) => item?.Name === data[name]) : data[name]
                  if (name === 'Assigner') {
                    value = options.find((item) => item?.Name === data?.Assigner?.UserId)
                  }

                  if (name === 'LastInspectionDate') {
                    return (
                      <TableCell key={j} classes={{ root: classnames(classes.cell) }}>
                        <MobileDatePicker
                          className={classes.textField}
                          label=""
                          format="MM/dd/yyyy"
                          value={value || null}
                          onChange={handleDateChange}
                          disabled={isInspection && isEdit}
                          disableFuture
                          slotProps={{
                            textField: {
                              InputProps: {
                                endAdornment: <InputAdornment position="end" />,
                                classes: {
                                  root: classes.cellInputRoot,
                                  input: classes.cellInput,
                                  multiline: classes.multiline,
                                },
                              },
                            },
                          }}
                        />
                      </TableCell>
                    )
                  }

                  if (isBoolean) {
                    return (
                      <TableCell key={j} classes={{ root: classnames(classes.cell) }}>
                        <Checkbox
                          checked={value}
                          onChange={() => booleanChange(name, !value)}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </TableCell>
                    )
                  }

                  if (options.length > 0) {
                    return (
                      <TableCell key={j} classes={{ root: classnames(classes.cell) }}>
                        <Autocomplete
                          disableClearable
                          onChange={(e, value) => handleChange(e, name, value)}
                          className={classes.textField}
                          options={options}
                          value={value || ''}
                          disabled={disabled}
                          getOptionLabel={(option: any) => option?.DisplayText?.en || option?.FileName || option}
                          classes={{
                            inputRoot: classes.autocomplete,
                            listbox: classes.listbox,
                            paper: classnames({ [classes.option]: !isMax, [classes.paperMax]: isMax }),
                          }}
                          renderInput={(params) => <TextField {...params} name={name} variant="outlined" />}
                        />
                      </TableCell>
                    )
                  }
                  return (
                    <TableCell key={j} classes={{ root: classnames(classes.cell) }}>
                      <TextField
                        name={name}
                        value={value}
                        variant="outlined"
                        className={classes.textField}
                        multiline={multiline}
                        maxRows={2}
                        SelectProps={{
                          displayEmpty: true,
                          classes: {
                            select: classes.select,
                          },
                          type: 'search',
                          MenuProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cellInputRoot,
                            input: classes.cellInput,
                            multiline: classes.multiline,
                          },
                        }}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          if (e.key === '/' && name === 'QrName') {
                            e.preventDefault()
                          }
                        }}
                      />
                    </TableCell>
                  )
                })}
              </TableRow>

              {isInstallation && !isEdit ? null : (
                <EquipmentTable
                  configEquipment={isInstallation ? installationEquipment : configEquipment}
                  roundType={data.RoundType}
                  equipments={Equipments}
                  changeRef={ref}
                  action={action}
                  setAction={setAction}
                  showPrint={showPrint}
                  linkedPM={data.LinkedPM}
                  colSpan={configData.length + 1}
                  isInstallation={isInstallation}
                  gId={selectedGroupId}
                  bId={selectedPropertyId}
                  setData={setData}
                  isAngus={!!data.AngusPropertyId}
                  SREnable={data.SREnable}
                  SRDisabled={data.SRDisabled}
                />
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  )
}

function canSave(data: DataItem, Equipments: EquipmentsItem[], requiredMsg: string, EquipmentErrMsg: string) {
  const { QrName, Times, Period, RoundType, Frequency, OfflineEnable } = data

  if ([QrName].some((v) => ['', undefined].includes(v as string))) {
    return { hasError: true, msg: `${requiredMsg} are Required` }
  }

  if (Frequency !== 'AdHoc' && [Times, Period].some((v) => ['', undefined].includes(v as string))) {
    return { hasError: true, msg: `${requiredMsg} are Required` }
  }

  if (RoundType === EquipmentFrom.Installation) {
    return { hasError: false, msg: '' }
  }

  if (Equipments.length === 0) {
    return { hasError: true, msg: 'Equipment are Required' }
  }

  if (OfflineEnable && Equipments.some((v) => v.ScanRequired)) {
    return { hasError: true, msg: 'Scan Required can not be selected when Offline Mode is enabled' }
  }

  const signIdArr = Equipments.map((v) => v.SignId).filter((v) => v)
  if (signIdArr.length !== new Set(signIdArr).size) {
    return { hasError: true, msg: 'Sign ID is Duplicate' }
  }

  for (const { FormTemplateFields, canSave, NewQr, SignId, SignIdChecked } of Equipments) {
    if (canSave === false) {
      return { hasError: true, msg: `${EquipmentErrMsg} are Required` }
    }

    if (NewQr === false && !SignId) {
      return { hasError: true, msg: 'Sign ID is Required' }
    }

    if (NewQr === false && SignIdChecked === false) {
      return { hasError: true, msg: 'Sign id doesn’t exist' }
    }

    let msg = ''
    const hasError = FormTemplateFields.some((v) => {
      const value = v.canSave === false
      if (value) msg = v?.errMsg as string
      return value
    })

    if (hasError) {
      return { hasError: true, msg }
    }
  }

  return { hasError: false, msg: '' }
}

export default EngineeringBuilder
