import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Box, Button } from '@mui/material'
import { FileUploadOutlined, InsertDriveFile, Cancel } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { toBase64 } from '@/utils'
import { uploadFileBase64 } from '@api'
import CONFIG from '@constant/config'
import SnackBar from '@component/SnackBar'
import CustomTextField from '@component/Form/CustomTextField'
import uuid from 'react-uuid'
import classnames from 'classnames'

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      marginBottom: '18px',
      alignItems: 'center',
    },
    list: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    title: {
      fontSize: 14,
      marginBottom: 24,
    },
    uploadTitle: {
      paddingRight: 30,
      fontSize: 16,
      minWidth: 300,
    },
    upload: {
      display: 'flex',
      height: 42,
      minWidth: 150,
      borderRadius: '4px',
      fontSize: 15,
      padding: '8px 16px 8px 22px',
      fontWeight: 500,
    },
    textInput: {
      marginTop: 0,
      flex: 1,
      marginRight: 24,
    },
    fileName: {
      width: 'webkit-fill-available',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      cursor: 'pointer',
      flex: 1,
      backgroundColor: '#F5F5F5',
      padding: '8px',
      marginTop: 24,
      overflow: 'hidden',
    },
    fileNameText: {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: '0 8px',
    },
  }
})

interface Props {
  filePath: string
  value: string
  uploadText?: string
  reUploadText?: string
  label?: string
  className?: string
  buttonClassName?: string
  disabled?: boolean
  disabledInput?: boolean
  disabledUpload?: boolean
  onChange?: (file: string) => void
  displayMode?: 'row' | 'list' | 'none'
  accept?: string
}

const UploadFile: React.FC<Props> = ({
  filePath,
  value = '',
  disabled,
  className = '',
  buttonClassName = '',
  uploadText = 'Upload a File',
  reUploadText = 'Re-upload a File',
  disabledInput,
  disabledUpload,
  onChange,
  label = 'URL (optional)',
  displayMode = 'row',
  accept = '',
}) => {
  const classes = useStyles()
  const [fileUrl, setFileUrl] = useState(value)
  const id = useMemo(() => `UploadFile-${uuid()}`, [])
  const onFileChange = useCallback(
    async (event: any) => {
      const file = event.target.files?.[0] as File
      const { name, type } = file
      const base64 = await toBase64(file)
      const result: any = await uploadFileBase64({
        fileType: type,
        base64,
        fileName: `${filePath}/${Date.now()}-${name}`,
        bucket: CONFIG.s3_Bucket,
      })
      setFileUrl(result?.fileUrl)
      onChange?.(result?.fileUrl)
      SnackBar({ msg: 'Uploaded Successfully', type: 'success' })
    },
    [filePath, onChange]
  )

  const onInput = (value: string) => {
    setFileUrl(value)
    onChange?.(value)
  }

  useEffect(() => {
    setFileUrl(value)
  }, [value])

  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      className={classnames(classes.container, {
        [classes.list]: displayMode === 'list',
        [className]: true,
      })}
    >
      {displayMode === 'row' && (
        <CustomTextField
          variant="outlined"
          onInputChange={onInput}
          value={fileUrl}
          label={label}
          placeholder=""
          disabled={disabled || disabledInput}
          className={classes.textInput}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      <input id={id} style={{ display: 'none' }} onChange={onFileChange} type="file" accept={accept} />
      <label htmlFor={id}>
        <Button
          component="span"
          variant="contained"
          color="primary"
          className={classnames(classes.upload, { [buttonClassName]: true })}
          disabled={disabled || disabledUpload}
        >
          {fileUrl ? reUploadText : uploadText}
          <FileUploadOutlined sx={{ fontSize: '20px', marginLeft: '8px' }} />
        </Button>
      </label>
      {displayMode === 'list' && fileUrl && (
        <Box className={classes.fileName}>
          <InsertDriveFile style={{ fontSize: 24, color: '#1976D2' }} />
          <span className={classes.fileNameText} onClick={() => fileUrl && window.open(fileUrl, '_blank')}>
            {fileUrl ? fileUrl.substring(fileUrl.lastIndexOf('/') + 1) : 'No file uploaded'}
          </span>
          {fileUrl && <Cancel style={{ fontSize: 24, color: '#313131' }} onClick={() => onInput('')} />}
        </Box>
      )}
    </Box>
  )
}
export default UploadFile
