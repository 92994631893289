import SnackBar from '@component/SnackBar'
import { QRCodeFunctionType } from '@/types'
import { SOCIAL_REQUIRED } from '@component/EditPanel/SocialFormPanel'
import { STATIC_REQUIRED } from '@component/EditPanel/StaticPanel'
import { HTML_REQUIRED } from '@component/EditPanel/EditHtml'
import { PHONE_REQUIRED } from '@component/EditPanel/PhoneCallPanel'
import { GOOGLE_REQUIRED } from '@component/FormData/GoogleMapFormData'
import { FRONT_DESK_REQUIRED } from '@component/EditPanel/FrontDeskPanel'
import { DOWNLOAD_APP_REQUIRED } from '@component/EditPanel/DownloadAppPanel'
import { FIRE_EXTINGUISHER_REQUIRED, AED_INSPECTION_REQUIRED } from '@component/EditPanel/FireExtinguisherPanel'
import { INSPECTION_REQUIRED } from '@component/EditPanel/InspectionPanel'
import { ROUNDINSPECTION_REQUIRED } from '@component/EditPanel/RoundInspectionPanel'

const language = 'en'

export const toBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const downLoadFile = ({ url, prefix = '' }) => {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.send()
  xhr.onreadystatechange = function () {
    if (xhr.status === 200) {
      try {
        const eleLink = document.createElement('a')
        eleLink.download = `${prefix ? `${prefix}-` : ''}${url.substring(url.lastIndexOf('/') + 1)}`
        eleLink.href = URL.createObjectURL(xhr.response)
        document.body.appendChild(eleLink)
        eleLink.click()
        URL.revokeObjectURL(eleLink.href)
        document.body.removeChild(eleLink)
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }
}

export const formatPhoneNumber = (str) => {
  const cleaned = ('' + str).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    // const intlCode = (match[1] ? '+1 ' : '')
    return ['', '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return str
}

export const copyText = (text) => {
  const handleCopy = (e) => {
    if (!text) return
    e.clipboardData && e.clipboardData.setData('text/plain', text)
    e.preventDefault()
    SnackBar({ msg: 'Copied', type: 'success' })
    document.removeEventListener('copy', handleCopy)
  }

  document.addEventListener('copy', handleCopy)
  document.execCommand('copy')
}

const canSave = (required, item) => {
  const keys = Object.keys(required)
  const canSave =
    keys.filter((key) => {
      // @ts-ignore
      return !item?.[key]
    }).length < 1
  return canSave
}

export const handlerHub = (data) => {
  if (!data.Items) data.Items = []
  data.Items?.forEach((item) => {
    const { Name, TemplateFormData, Icon, FunctionalityType, TemplateId, QrType } = item || {}
    let CanSave = false
    if (!Name || !Icon || !FunctionalityType || !QrType) {
      CanSave = false
    } else {
      CanSave = isCanSavaQr(FunctionalityType, TemplateFormData, TemplateId)
      if (item.TemplateFormData) {
        item.TemplateFormData.CanSave = CanSave
      }
    }
    item.CanSave = CanSave
  })
  return data
}

export const isCanSavaQr = (funcType, item, tId) => {
  const templateId = tId || item?.TemplateId
  switch (funcType) {
    case QRCodeFunctionType.JoinWifi:
      if (item?.AlternateEnable) return !!item?.ExternalUrl && item?.ExternalUrl?.length > 8
      if (!item?.SSID || !item?.NetworkType || (item?.NetworkType !== 'nopass' && !item?.Password)) return false
      return true
    case QRCodeFunctionType.SocialMedia: {
      const keys = Object.keys(SOCIAL_REQUIRED)
      const canSave = keys.some((key) => {
        // @ts-ignore
        return !!item?.[key]
      })
      return canSave
    }
    case QRCodeFunctionType.GoogleMap:
      return canSave(GOOGLE_REQUIRED, item)
    case QRCodeFunctionType.PDF:
    case QRCodeFunctionType.EmbeddedFile:
      return !!item?.Url
    case QRCodeFunctionType.Html:
      if (item?.HtmlContent === '<p><br></p>') return false
      return canSave(HTML_REQUIRED, item)
    case QRCodeFunctionType.Static:
    case QRCodeFunctionType.Video:
    case QRCodeFunctionType.EmbeddedStaticUrl:
      return canSave(STATIC_REQUIRED, item)
    case QRCodeFunctionType.PhoneCall: {
      return canSave(PHONE_REQUIRED, item)
    }
    case QRCodeFunctionType.FrontDesk: {
      if (item?.AlternateEnable) return !!item?.ExternalUrl && item?.ExternalUrl?.length > 8
      return canSave(FRONT_DESK_REQUIRED, item)
    }
    case QRCodeFunctionType.DownloadApp:
      if (item?.AlternateEnable) return !!item?.ExternalUrl && item?.ExternalUrl?.length > 8
      return canSave(DOWNLOAD_APP_REQUIRED, item)
    case QRCodeFunctionType.FireExtinguisher: {
      if (item?.Fields.map((v) => v.FieldText?.en).includes('')) return false
      return canSave(FIRE_EXTINGUISHER_REQUIRED, item)
    }
    case QRCodeFunctionType.AEDInspection: {
      if (item?.Fields?.map((v) => v.FieldText?.en).includes('')) return false
      return canSave(AED_INSPECTION_REQUIRED, item)
    }
    case QRCodeFunctionType.BathroomService: {
      if (!item) return false
      const { Choices, FieldText } = item.Fields[0]
      if (Choices.map((v) => v?.Value).includes('')) return false
      if (item?.AlternateEnable) return !!item?.ExternalUrl && item?.ExternalUrl?.length > 8
      return !!FieldText?.en
    }
    case QRCodeFunctionType.Feedback: {
      if (
        !item ||
        !item.Questions ||
        !item.Questions[0].QuestionText ||
        !item.Questions[0].QuestionText[language] ||
        !item.Questions[1].Choices ||
        item.Questions[1].Choices.length === 0 ||
        item.Questions[1].Choices.some((i) => {
          return Object.keys(i?.Text) > 0 && i?.Text?.[language] === ''
        })
      )
        return false
      return true
    }
    case QRCodeFunctionType.CustomerFeedback: {
      const questions = item?.Questions?.slice(0, 3)
      if (!questions?.length) return false
      const showIndex = questions.findIndex((item) => !item.Hide)
      if (showIndex === -1) return false
      const emptyIndex = questions.findIndex((item) => !item.Hide && !item.QuestionText?.en)
      if (emptyIndex > -1) return false
      return true
    }
    case QRCodeFunctionType.LeadCapture:
    case QRCodeFunctionType.EVRequest:
    case QRCodeFunctionType.RoomRental:
    case QRCodeFunctionType.FieldMarketing:
    case QRCodeFunctionType.EventRegistration: {
      let Choices = []
      const isEv = funcType === QRCodeFunctionType.EVRequest
      if (!isEv && !item?.EventName) return false
      if (isEv && !item?.EventLocation) return false
      if (item?.Fields.map((v) => v.FieldText?.en).includes('')) return false
      item?.Fields.forEach((v) => {
        if (v?.Choices?.length > 0) {
          Choices.push(...v.Choices)
        }
      })
      if (Choices.map((v) => v.Text?.en).includes('')) return false
      return true
    }
    case QRCodeFunctionType.CheckIn: {
      let Choices = []
      if (item?.Fields.map((v) => v.FieldText?.en).includes('')) return false
      item?.Fields.forEach((v) => {
        if (v?.Choices?.length > 0) {
          Choices.push(...v.Choices)
        }
      })
      if (Choices.map((v) => v.Text?.en).includes('')) return false
      return !!(
        item?.EventName &&
        item?.EventLocation &&
        item?.StartDate &&
        item?.EndDate &&
        (!item?.EnableAttendanceCap || (item?.EnableAttendanceCap && !!item?.AttendanceCap))
      )
    }
    case QRCodeFunctionType.EquipmentInspection: {
      return true
      // return item?.SerialNo || item?.ExpirationDate || item?.Manufacturer || item?.ExtinguisherType
    }
    case QRCodeFunctionType.Inspection: {
      return canSave(INSPECTION_REQUIRED, item)
    }
    case QRCodeFunctionType.AngusServiceRequest: {
      return true
    }
    case QRCodeFunctionType.RoundInspection: {
      return canSave(ROUNDINSPECTION_REQUIRED, item)
    }
    case QRCodeFunctionType.AngusOnDemandWorkOrder: {
      return true
    }
    case QRCodeFunctionType.BurnPermit:
    case QRCodeFunctionType.Maintenance:
    case QRCodeFunctionType.CommentCard:
    case QRCodeFunctionType.GuestCheckin:
    case QRCodeFunctionType.TourCheckin:
    case QRCodeFunctionType.MobileEmployee: {
      let Choices = []
      if (item?.Fields.map((v) => v.FieldText?.en).includes('')) return false
      item?.Fields.forEach((v) => {
        if (v?.Choices?.length > 0) {
          Choices.push(...v.Choices)
        }
      })
      if (Choices.map((v) => v.Text?.en).includes('')) return false
      if (funcType === QRCodeFunctionType.MobileEmployee) {
        if (Choices.map((v) => v.Value).includes('')) return false
      }
      return true
    }
    case QRCodeFunctionType.Renewal: {
      let Choices = []
      if (item?.Fields.map((v) => v.FieldText?.en).includes('')) return false
      item?.Fields.forEach((v) => {
        if (v?.Choices?.length > 0) {
          Choices.push(...v.Choices)
        }
      })
      if (Choices.map((v) => v.Text?.en).includes('')) return false

      const QuestionText = item?.Questions[0]?.QuestionText
      if (item?.Questions?.[1]?.Choices.map((v) => v?.Text?.en).includes('')) return false
      return !!QuestionText?.en
    }
    case QRCodeFunctionType.LadderSafety: {
      if (!item) return false
      const { Ladders = [] } = item
      return Ladders?.length === 2 && !!Ladders?.[0]?.Name && !!Ladders?.[1]?.Name
    }
    case QRCodeFunctionType.MoveOut:
    case QRCodeFunctionType.MoveOutResident: {
      if (!item) return false
      const { Sections = [] } = item
      if (!Array.isArray(Sections) || Sections === 0) return false
      const emptyIndex = Sections.findIndex((item) => {
        const { SectionName, Items } = item
        const emptyItemsIndex = Items.findIndex((item) => {
          const { ItemName, Buttons } = item
          return !ItemName || !Array.isArray(Buttons) || Buttons.length === 0
        })
        return !SectionName || emptyItemsIndex > -1
      })
      return emptyIndex === -1
    }
    case QRCodeFunctionType.ServiceRequests:
    case QRCodeFunctionType.ActivitySchedule:
    case QRCodeFunctionType.NewSupplier:
    case QRCodeFunctionType.RoundReport:
    case QRCodeFunctionType.CleaningRoundReport:
    case QRCodeFunctionType.SecurityPatrolDashboard:
    case QRCodeFunctionType.PropertyCHARReport:
    case QRCodeFunctionType.JobReport:
    case QRCodeFunctionType.EquipmentLookup:
    case QRCodeFunctionType.SupplierList:
    case QRCodeFunctionType.TenantHealth:
    case QRCodeFunctionType.GuestCheckOut:
    case QRCodeFunctionType.SupplierCheckReport:
    case QRCodeFunctionType.GuestLogReport:
    case QRCodeFunctionType.FeedbackLocationReport:
    case QRCodeFunctionType.TenantPerformance:
    case QRCodeFunctionType.Permit:
    case QRCodeFunctionType.Inventory:
    case QRCodeFunctionType.SupplierCheckin:
    case QRCodeFunctionType.StudentCheckin:
    case QRCodeFunctionType.RoofAccess:
    case QRCodeFunctionType.TenantHVAC:
    case QRCodeFunctionType.BEServiceRequest:
    case QRCodeFunctionType.BathroomServiceReport:
    case QRCodeFunctionType.BuildingInspectionDashboard:
    case QRCodeFunctionType.PortfolioInspectionDashboard: {
      return true
    }
    case QRCodeFunctionType.ResidentCheckOut: {
      return item?.Fields.length > 0
    }
    case QRCodeFunctionType.RoofAccessCarousel: {
      let value = item?.Carousel.length > 0
      item?.Carousel.forEach((item) => {
        if (!item?.Name || !item?.ItemId || !item?.HubId) {
          value = false
        }
      })
      return value
    }
    default:
      return !!templateId
  }
}
