import React, { useEffect, useState, useMemo } from 'react'
import { Box, Typography, Autocomplete, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import TableList, { TableConfig, Pagination } from '@component/Tabs/TableList'
import { getActivityList, getActivityFilter } from '@api'
import { useSelector } from 'react-redux'
import useSetState from '@hooks/useSetState'
import { DEFAULT_DATE_RANGE_WTD } from '@/global'
import CustomDateRange from '@component/DateSelect/CustomDateRange'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'
import { ExpandMore } from '@mui/icons-material'
import PhotoTip from '@component/Round/PhotoTip'

const CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Date',
    source: 'Date',
    isDate: true,
    width: 122,
    formatKey: 'keyboardDate',
  },
  {
    width: 250,
    label: 'Engineer Name',
    source: 'FullName',
    align: 'left',
  },
  {
    width: 250,
    label: 'Vendor Name',
    align: 'left',
    source: 'VendorName',
  },
  {
    width: 150,
    label: 'Work Type',
    source: 'WorkType',
    align: 'left',
  },
  {
    width: 300,
    label: 'Comment',
    source: 'Description',
    align: 'left',
  },
  {
    label: 'Photos',
    source: 'UploadedFiles',
    width: 145,
    align: 'center',
    convertItem: (item) => <PhotoTip photo={item.UploadedFiles} />,
  },
]

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#F5F5F5',
    flex: 1,
    minWidth: 1200,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    '& .borderLeft': {
      borderLeft: '1px solid #000000',
    },
    margin: '-100px -24px 0',
  },
  header: {
    display: 'flex',
    padding: '18px 24px',
    justifyContent: 'flex-end',
  },
  row: {
    display: 'flex',
    padding: '18px 24px',
  },
  card: {
    margin: '25px 24px 0',
    borderRadius: 8,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    padding: '24px 24px 36px',
    backgroundColor: 'white',
  },
  title: {
    fontSize: 20,
    color: '#211F1F',
    fontWeight: 600,
    marginBottom: 24,
  },
  rootClass: {
    display: 'grid',
  },
}))

const JobReportContent: React.FC<{
  gId: string
  range?: DateRange<Dayjs>
  isPuppeteer?: boolean
  workType?: string
  engineer?: string
  vendor?: string
}> = ({ gId, range, isPuppeteer = false, workType, engineer, vendor }) => {
  const classes = useStyles()
  const [{ data, total, page, rowsPerPage }, setState] = useSetState({
    JobReport: [],
    data: [],
    loading: true,
    page: 0,
    total: 0,
    rowsPerPage: 25,
  })

  const params = useMemo(() => {
    const params = { gId, workType, engineer, vendor } as any
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    return params
  }, [range, gId, workType, engineer, vendor])

  const pagination = useMemo<Pagination>(
    () => ({
      count: total,
      page,
      rowsPerPage,
      onPageChange: (page) => setState({ page }),
      onRowsPerPageChange: (rowsPerPage) => setState({ rowsPerPage, page: 0 }),
    }),
    [page, rowsPerPage, setState, total]
  )

  useEffect(() => {
    setState({ loading: true })
    localStorage.setItem(
      'DashboardDate',
      JSON.stringify({
        StartDate: params?.startDate,
        EndDate: params?.endDate,
      })
    )
  }, [params, setState])

  useEffect(() => {
    setState({ page: 0 })
  }, [setState, engineer, workType])

  useEffect(() => {
    getActivityList({
      filter: JSON.stringify({ ...params }),
      sort: JSON.stringify(['Date', 'DES']),
      pagination: JSON.stringify({ page: page, perPage: rowsPerPage }),
    }).then((res: any) => {
      setState({ data: res?.Activities || [], total: res.Total })
    })
  }, [params, page, rowsPerPage, setState])

  return (
    <Box className={classes.card}>
      <Typography className={classes.title}>Jobs Report</Typography>
      <TableList
        config={CONFIG}
        data={data || []}
        rowKey="id"
        rootClass={classes.rootClass}
        tableLayout="fixed"
        orderBy="Date"
        pagination={pagination}
      />
    </Box>
  )
}

interface FilterITF {
  Name: string
  Count: number
}
const JobReport: React.FC = () => {
  const classes = useStyles()
  const { selectedGroupId: gId } = useSelector((state) => state.profile)
  const [range, setRange] = useState<any>(DEFAULT_DATE_RANGE_WTD)
  const [{ workType, workTypes, engineer, engineers, vendors, vendor }, setState] = useSetState<{
    workType?: string
    workTypes?: FilterITF[]
    engineer?: string
    engineers?: FilterITF[]
    vendor?: string
    vendors?: FilterITF[]
  }>({ workTypes: [] })
  useEffect(() => {
    getActivityFilter<{ Engineer: FilterITF[]; WorkType: FilterITF[]; Vendors: FilterITF[] }>({ gId }).then((res) => {
      setState({ workTypes: res.WorkType || [], engineers: res.Engineer || [], vendors: res?.Vendors || [] })
    })
  }, [gId, setState])

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Autocomplete
          value={engineers?.find((item) => item.Name === engineer)}
          onChange={(e, newValue) => {
            setState({ engineer: newValue?.Name })
          }}
          options={engineers || []}
          sx={{ width: '160px', marginRight: '24px' }}
          getOptionLabel={(option: any) => option?.Name || ''}
          renderInput={(params) => <TextField {...params} label="Engineer Name" />}
          popupIcon={<ExpandMore />}
        />
        <Autocomplete
          value={vendors?.find((item) => item.Name === vendor)}
          onChange={(e, newValue) => {
            setState({ vendor: newValue?.Name })
          }}
          options={vendors || []}
          sx={{ width: '160px', marginRight: '24px' }}
          getOptionLabel={(option: any) => option?.Name || ''}
          renderInput={(params) => <TextField {...params} label="Vendor Name" />}
          popupIcon={<ExpandMore />}
        />
        <Autocomplete
          value={workTypes?.find((item) => item.Name === workType)}
          onChange={(e, newValue) => {
            setState({ workType: newValue?.Name })
          }}
          options={workTypes || []}
          sx={{ width: '160px', marginRight: '24px' }}
          getOptionLabel={(option: any) => option?.Name || ''}
          renderInput={(params) => <TextField {...params} label="Work Type" />}
          popupIcon={<ExpandMore />}
        />
        <CustomDateRange value={range} onChange={(value) => setRange(value)} label="Date Range" />
      </div>
      <JobReportContent gId={gId} range={range} workType={workType} engineer={engineer} vendor={vendor} />
    </div>
  )
}

export default JobReport
