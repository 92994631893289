import React, { useImperativeHandle, useState } from 'react'
import PopupState, { bindFocus, bindPopover, bindToggle } from 'material-ui-popup-state'
import { Button, SvgIcon } from '@mui/material'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import { makeStyles } from '@mui/styles'
import FilterItem from '@component/Filter/FilterItem'
import { ClearRounded } from '@mui/icons-material'
import { PopoverList } from '@/qrcodes/myqrcode/Aside'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filter: {
    fontWeight: 'normal',
    color: '#466CB5',
  },
  svg: {
    marginRight: 8,
    color: '#466CB5',
  },
  hoverPaper: {
    border: 'none',
    padding: '8px 0',
  },
  clearItem: {
    marginLeft: 8,
    height: 25,
    color: '#7F7F7F',
    borderRadius: 18,
    fontSize: 10,
    textTransform: 'none',
    padding: '0 16px',
    backgroundColor: '#FAFAFA',
    border: '0.5px solid #7F7F7F',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
    padding: 16,
  },
  clearContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    gap: 8,
    flex: 1,
  },
}))

interface Props {
  filters?: IOption[]
  defaultFilters?: IOption[]
  isMulti?: boolean
  onChange: (value: Array<IOption>) => void
  children?: React.ReactNode
  oneByOne?: boolean //一个类型只能选一个
  showCount?: boolean
  forwardRef?: React.MutableRefObject<{ clearAll: () => void }>
}
const Filter: React.FC<Props> = ({
  filters,
  defaultFilters,
  onChange,
  isMulti,
  children,
  oneByOne,
  showCount,
  forwardRef,
}) => {
  const classes = useStyles()
  const [selects, setSelects] = useState<Array<IOption>>(defaultFilters || [])
  const [results, setResults] = useState<Array<IOption>>(defaultFilters || [])
  const toggleFilter = (item: IOption) => {
    setSelects((prevState) => {
      let nextSelects: Array<IOption> = []
      if (isMulti || oneByOne) {
        const index = prevState.findIndex((data) => item.value === data.value)
        if (index > -1) {
          prevState.splice(index, 1)
          nextSelects = [...prevState]
        } else {
          if (oneByOne) {
            prevState = prevState.filter((v) => v.parent !== item.parent)
          }
          prevState.push(item)
          nextSelects = [...prevState]
        }
      } else {
        nextSelects = item ? [item] : []
      }
      !isMulti && onChange(nextSelects)
      return nextSelects
    })
  }
  const clearFilter = (item: IOption) => {
    let nextSelects = [...selects]
    if (isMulti || oneByOne) {
      const index = selects.findIndex((data) => item.value === data.value)
      nextSelects.splice(index, 1)
    } else {
      nextSelects = []
    }
    setSelects(nextSelects)
    setResults([...nextSelects])
    onChange(nextSelects)
  }
  const onCancel = () => {
    setSelects([...results])
  }
  const onConfirm = () => {
    setResults([...selects])
    onChange([...selects])
  }
  useImperativeHandle(
    forwardRef,
    () => ({
      clearAll: () => {
        setSelects([])
        setResults([])
        onChange([])
      },
    }),
    [onChange]
  )
  const clearList = isMulti ? results : selects
  return (
    <div className={classes.container}>
      <div className={classes.clearContainer}>
        {clearList?.map((item) => {
          const { label, value } = item
          return (
            <Button
              key={value + label}
              endIcon={<ClearRounded htmlColor="#7F7F7F" />}
              onClick={() => {
                clearFilter(item)
              }}
              className={classes.clearItem}
            >
              {label}
            </Button>
          )
        })}
      </div>
      {children}
      <PopupState variant="popover" popupId="filterPopover">
        {(popupState) => (
          <div>
            <Button className={classes.filter} {...bindToggle(popupState)} {...(isMulti ? {} : bindFocus(popupState))}>
              <SvgIcon className={classes.svg}>
                <g>
                  <path d="M0,0h24 M24,24H0" fill="none" />
                  <path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
                  <path d="M0,0h24v24H0V0z" fill="none" />
                </g>
              </SvgIcon>
              Filter
            </Button>
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              classes={{ paper: classes.hoverPaper }}
            >
              {filters?.map((item) => {
                const { label, value, child = [], count } = item
                if (child.length) {
                  return (
                    <PopoverList isEmpty={false} label={label} key={value} popupId={value as string}>
                      {child?.map((item) => {
                        const { label, value, count } = item
                        return (
                          <FilterItem
                            toggleFilter={() => {
                              !isMulti && popupState.close()
                              toggleFilter(item)
                            }}
                            isSelected={selects.findIndex((item) => item.value === value) > -1}
                            label={label}
                            key={value}
                            value={value}
                            count={count}
                          />
                        )
                      })}
                    </PopoverList>
                  )
                }
                return (
                  <FilterItem
                    toggleFilter={() => {
                      !isMulti && popupState.close()
                      toggleFilter(item)
                    }}
                    isSelected={selects.findIndex((item) => item.value === value) > -1}
                    label={label}
                    key={label}
                    value={value}
                    count={count}
                  />
                )
              })}
              {isMulti && (
                <div className={classes.buttonGroup}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      popupState.close()
                      onCancel()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      popupState.close()
                      onConfirm()
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              )}
            </HoverPopover>
          </div>
        )}
      </PopupState>
    </div>
  )
}

export default Filter
