import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CustomDateRange from '@component/DateSelect/CustomDateRange'
import CommonCard from '@component/Card/CommonCard'
import Images from '@assets/images'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DEFAULT_DATE_RANGE_WTD, STAR_OPTIONS } from '@/global'
import { useSelector } from 'react-redux'
import { getBuildingResidentFeedbackSummary, getResidentFeedbackSummary } from '@api'
import { ResidentFeedbackITF } from '@/types/survey'
import TableList, { TableConfig } from '@component/Tabs/TableList'
import Echarts, { EchartsProps, WordCloudProps } from '@component/Chart/Echarts'
import { IWordCloud } from '@/types'
import Rating from '@mui/lab/Rating'
import Filter from '@component/Filter/Filter'

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: -80,
    minWidth: 1200,
    backgroundColor: '#F5F5F5',
    flex: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    // padding: '0 24px 18px',
  },
  row: {
    display: 'flex',
    marginBottom: 24,
  },
  card: {
    borderRadius: 8,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    padding: '24px 24px 36px',
    backgroundColor: 'white',
    marginBottom: 24,
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 24,
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    height: 78,
    lineHeight: '78px',
    color: '#211F1F',
    border: '1px solid #BCBCBC',
    backgroundColor: '#F0F6FC',
    borderRadius: 8,
    padding: '0 24px',
    marginBottom: 24,
  },
  tableHeader: {
    fontSize: 16,
    height: 48,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  ratingBox: {
    display: 'flex',
    alignItems: 'center',
  },
  rating: {
    textAlign: 'left',
  },
  ratingText: {
    flex: 1,
    textAlign: 'left',
  },
  br: {
    height: 1,
    background: '#E8E8E8',
    marginBottom: 24,
  },
  properties: {
    display: 'flex',
    gap: 20,
    justifyContent: 'center',
  },
  commentFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  right: {
    gap: 24,
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 32,
    '&:before': {
      zIndex: 1,
      content: '""',
      position: 'absolute',
      left: 0,
      top: '20%',
      height: '60%',
      width: 1,
      backgroundColor: '#BCBCBC',
    },
  },
  rightClassName: {
    flex: 2,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  leftClass: {
    width: 234,
    flex: 'unset',
  },
  label: {
    color: '#707070',
  },
  dot: {
    flex: 1,
    minWidth: 10,
    margin: '0 16px',
    borderTop: '1px dashed #C2C2C2',
  },
  buildingName: {
    fontWeight: 500,
    paddingRight: 16,
  },
}))
const getWordOption = (seriesData: IWordCloud[]): WordCloudProps & EchartsProps => {
  return {
    id: 'cloudchart',
    type: 'wordCloud',
    // shape: 'circle',
    width: '100%',
    height: '100%',
    seriesData: [...seriesData].slice(0, 100).map((item: IWordCloud) => {
      const colorArr = ['#E30613', '#F8C636', '#4BCF5D'] //红色-消极 橙色-中性 绿色-积极
      let index = 1
      if (item.SentimentScore > 0) {
        index = 2
      }
      if (item.SentimentScore < 0) {
        index = 0
      }
      return {
        name: item.Label,
        value: item.Value,
        textStyle: {
          color: colorArr[index] || 'red',
        },
      }
    }),
  }
}
const ACTION_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Date of review',
    source: 'SubmitDate',
    formatKey: 'keyboardDate',
    isDate: true,
    width: 120,
  },
  {
    label: 'Star Rating',
    source: 'Detail',
    width: 150,
    convertItem: (item: any) => {
      return <Rating name="grid-size-large" readOnly value={item.Rating} precision={0.5} />
    },
    align: 'left',
  },
  {
    label: 'Comment',
    source: 'Snippet',
    align: 'left',
  },
]
const COM: React.FC<{ name1?: string; rating1?: number; name2?: string; rating2?: number }> = ({
  name1,
  rating1,
  name2,
  rating2,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.right}>
      <Box className={classes.item}>
        <Typography className={classes.label}>Highest Rated Building</Typography>
        <Box className={classes.dot} />
        <Typography className={classes.buildingName}>{name1 || 'NA'}</Typography>
        <Rating readOnly value={rating1 || null} precision={0.5} />
      </Box>
      <Box className={classes.item}>
        <Typography className={classes.label}>Lowest Rated Building</Typography>
        <Box className={classes.dot} />
        <Typography className={classes.buildingName}>{name2 || 'NA'}</Typography>
        <Rating readOnly value={rating2 || null} precision={0.5} />
      </Box>
    </Box>
  )
}

const ResidentFeedback: React.FC<{ isBuilding?: boolean }> = ({ isBuilding }) => {
  const classes = useStyles()
  const [range, setRange] = useState<any>(DEFAULT_DATE_RANGE_WTD)
  const { selectedGroupId, selectedPropertyId } = useSelector((state) => state.profile)
  const [data, setData] = useState<ResidentFeedbackITF>()
  const [filter, setFilter] = useState<number | undefined>()
  const forwardRef = useRef<{ clearAll: () => void }>({
    clearAll: () => {},
  })
  const {
    GoogleRatingAvg,
    GoogleRatingCount,
    GoogleRatingClouds,
    GoogleRatingTopOnBuilding,
    GoogleRatingBottomOnBuilding,
    FeedbackCount,
    FeedbackCommentAvg,
    FeedbackCommentClouds,
    CommentBottomOnBuilding,
    CommentTopOnBuilding,
    FeedbackComments,
  } = data || {}
  const { feedbackWordClouds, feedbackWordClouds2 } = useMemo(() => {
    const feedbackWordClouds = getWordOption(GoogleRatingClouds || [])
    const feedbackWordClouds2 = getWordOption(FeedbackCommentClouds || [])

    return {
      feedbackWordClouds,
      feedbackWordClouds2,
    }
  }, [FeedbackCommentClouds, GoogleRatingClouds])
  const { rightComponent, rightClassName, leftClass } = useMemo(() => {
    const heighest = GoogleRatingTopOnBuilding?.[0]
    const lowest = GoogleRatingBottomOnBuilding?.[0]
    if (isBuilding) return { rightComponent: undefined }
    const rightComponent = (
      <COM
        name1={heighest?.BuildingName}
        rating1={heighest?.RatingAvg}
        name2={lowest?.BuildingName}
        rating2={lowest?.RatingAvg}
      />
    )
    return {
      rightComponent,
      rightClassName: classes.rightClassName,
      leftClass: classes.leftClass,
    }
  }, [isBuilding, GoogleRatingTopOnBuilding, GoogleRatingBottomOnBuilding, classes])
  const { right2Component, right2ClassName, left2Class } = useMemo(() => {
    const heighest = CommentTopOnBuilding?.[0]
    const lowest = CommentBottomOnBuilding?.[0]
    if (isBuilding) return { rightComponent: undefined }
    const right2Component = (
      <COM
        name1={heighest?.BuildingName}
        rating1={heighest?.RatingAvg}
        rating2={lowest?.RatingAvg}
        name2={lowest?.BuildingName}
      />
    )
    return {
      right2Component,
      right2ClassName: classes.rightClassName,
      left2Class: classes.leftClass,
    }
  }, [isBuilding, CommentTopOnBuilding, CommentBottomOnBuilding, classes])
  const getData = useCallback(async () => {
    const params = { gId: selectedGroupId }
    isBuilding && Object.assign(params, { bId: selectedPropertyId })
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    if (isBuilding) {
      const result = await getBuildingResidentFeedbackSummary<ResidentFeedbackITF>(params)
      setData(result)
    } else {
      const result = await getResidentFeedbackSummary<ResidentFeedbackITF>(params)
      setData(result)
    }
    forwardRef.current.clearAll()
    setFilter(undefined)
  }, [isBuilding, range, selectedGroupId, selectedPropertyId])
  const onChangeFilter = (value: Array<IOption>) => {
    setFilter(value?.[0]?.value as number)
  }
  useEffect(() => {
    getData()
  }, [getData])
  const comments = filter ? (FeedbackComments || []).filter((item) => item.Rating === filter) : FeedbackComments || []
  return (
    <div className={classes.paper}>
      <div className={classes.headerRight}>
        <CustomDateRange value={range} onChange={(value) => setRange(value)} label="Date Range" />
      </div>
      <Typography className={classes.title}>Google Review</Typography>
      <div className={classes.row}>
        <CommonCard loading={!data} label="Number Of Reviews" value={GoogleRatingCount || 0} icon={Images.star} />
        <CommonCard
          rightComponent={rightComponent}
          className={rightClassName}
          leftClass={leftClass}
          loading={!data}
          label="Average Rating"
          value={GoogleRatingAvg || 0}
          icon={Images.avg}
        />
      </div>
      <div className={classes.card}>
        <TableList
          minWidth="unset"
          config={[
            {
              label: isBuilding ? 'Building Sentiment' : 'Portfolio Word Cloud',
              source: 'CategoryAnalysis',
              className: classes.tableHeader,
              align: 'left',
              convertItem: (item, index) => {
                const hasData = feedbackWordClouds?.id && feedbackWordClouds.seriesData.length > 0
                return (
                  <div className={classes.ratingBox} style={{ height: hasData ? 450 : 100, justifyContent: 'center' }}>
                    {hasData ? <Echarts {...feedbackWordClouds} id="feedbackWordClouds" /> : 'No Data'}
                  </div>
                )
              },
            },
          ]}
          data={[{}]}
          tableLayout="fixed"
        />
      </div>

      {!isBuilding && (
        <div className={classes.card}>
          <Typography className={classes.subTitle}>Top & Bottom Rated Properties</Typography>
          <Box className={classes.properties}>
            {(GoogleRatingTopOnBuilding || []).length > 0 && (
              <TableList
                minWidth="unset"
                config={[
                  {
                    label: 'Top 5 Properties',
                    source: 'CategoryAnalysis',
                    align: 'center',
                    convertItem: (item, index) => {
                      return (
                        <div className={classes.ratingBox}>
                          <Typography className={classes.ratingText}>{item.BuildingName}</Typography>
                          <Rating
                            className={classes.rating}
                            name="grid-size-large"
                            readOnly
                            value={item.RatingAvg}
                            precision={0.5}
                          />
                        </div>
                      )
                    },
                  },
                ]}
                data={GoogleRatingTopOnBuilding || []}
                tableLayout="fixed"
              />
            )}
            <TableList
              minWidth="unset"
              config={[
                {
                  label: 'Bottom 5 Properties',
                  source: 'CategoryAnalysis',
                  align: 'center',
                  convertItem: (item, index) => {
                    return (
                      <div className={classes.ratingBox}>
                        <Typography className={classes.ratingText}>{item.BuildingName}</Typography>
                        <Rating
                          className={classes.rating}
                          name="grid-size-large"
                          readOnly
                          value={item.RatingAvg}
                          precision={0.5}
                        />
                      </div>
                    )
                  },
                },
              ]}
              data={GoogleRatingBottomOnBuilding || []}
              tableLayout="fixed"
            />
          </Box>
        </div>
      )}
      <div className={classes.br} style={{ marginTop: 24 }} />
      <Typography className={classes.title}>Activate Feedback</Typography>
      <div className={classes.row}>
        <CommonCard loading={!data} label="Number Of Reviews" value={FeedbackCount || 0} icon={Images.star} />
        <CommonCard
          rightComponent={right2Component}
          className={right2ClassName}
          leftClass={left2Class}
          loading={!data}
          label="Average Rating"
          value={FeedbackCommentAvg || 0}
          icon={Images.avg}
        />
      </div>
      <div className={classes.card}>
        <TableList
          minWidth="unset"
          config={[
            {
              label: isBuilding ? 'Building Sentiment' : 'Portfolio Word Cloud',
              source: 'CategoryAnalysis',
              className: classes.tableHeader,
              align: 'left',
              convertItem: (item, index) => {
                const hasData = feedbackWordClouds2?.id && feedbackWordClouds2.seriesData.length > 0
                return (
                  <div className={classes.ratingBox} style={{ height: hasData ? 450 : 100, justifyContent: 'center' }}>
                    {hasData ? <Echarts {...feedbackWordClouds2} id="feedbackWordClouds2" /> : 'No Data'}
                  </div>
                )
              },
            },
          ]}
          data={[{}]}
          tableLayout="fixed"
        />
      </div>
      {!isBuilding && (
        <div className={classes.card}>
          <Typography className={classes.subTitle}>Top & Bottom Rated Properties</Typography>
          <Box className={classes.properties}>
            {(CommentTopOnBuilding || [])?.length > 0 && (
              <TableList
                minWidth="unset"
                config={[
                  {
                    label: 'Top 5 Properties',
                    source: 'CategoryAnalysis',
                    align: 'center',
                    convertItem: (item, index) => {
                      return (
                        <div className={classes.ratingBox}>
                          <Typography className={classes.ratingText}>{item.BuildingName}</Typography>
                          <Rating
                            className={classes.rating}
                            name="grid-size-large"
                            readOnly
                            value={item.RatingAvg}
                            precision={0.5}
                          />
                        </div>
                      )
                    },
                  },
                ]}
                data={CommentTopOnBuilding || []}
                tableLayout="fixed"
              />
            )}
            <TableList
              minWidth="unset"
              config={[
                {
                  label: 'Bottom 5 Properties',
                  source: 'CategoryAnalysis',
                  align: 'center',
                  convertItem: (item, index) => {
                    return (
                      <div className={classes.ratingBox}>
                        <Typography className={classes.ratingText}>{item.BuildingName}</Typography>
                        <Rating
                          className={classes.rating}
                          name="grid-size-large"
                          readOnly
                          value={item.RatingAvg}
                          precision={0.5}
                        />
                      </div>
                    )
                  },
                },
              ]}
              data={CommentBottomOnBuilding || []}
              tableLayout="fixed"
            />
          </Box>
        </div>
      )}
      <div className={classes.card}>
        <Box className={classes.commentFilter}>
          <Typography className={classes.subTitle}>Resident Comments</Typography>
          <Filter forwardRef={forwardRef} filters={STAR_OPTIONS} onChange={onChangeFilter} oneByOne />
        </Box>
        <TableList config={ACTION_CONFIG} data={comments} tableLayout="fixed" />
      </div>
    </div>
  )
}

export default ResidentFeedback
