import React, { useState } from 'react'
import PopupState, { bindPopover, bindToggle } from 'material-ui-popup-state'
import { Button, TextField } from '@mui/material'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import { makeStyles } from '@mui/styles'
import FilterItem from '@component/Filter/FilterItem'
import get from 'lodash/get'
import classnames from 'classnames'
import { ExpandMore } from '@mui/icons-material'
import InputAdornment from '@mui/material/InputAdornment'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filter: {
    fontWeight: 'normal',
    color: '#466CB5',
  },
  svg: {
    marginRight: 8,
    color: '#466CB5',
  },
  hoverPaper: {
    border: 'none',
  },
  clearItem: {
    marginLeft: 8,
    height: 25,
    color: '#7F7F7F',
    borderRadius: 18,
    fontSize: 10,
    textTransform: 'none',
    padding: '0 16px',
    backgroundColor: '#FAFAFA',
    border: '0.5px solid #7F7F7F',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
    padding: 16,
  },
  clearContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    gap: 8,
    flex: 1,
  },
}))

interface IItemKey {
  label?: string
  value?: string
}
interface Props<T> {
  data?: T[]
  defaultValue?: T[]
  itemKey?: IItemKey
  isMulti?: boolean
  label?: string
  className?: string
  disabled?: boolean
  onChange?: (value: Array<T>) => void
}
const covertShowData = <T extends string | IOption>(itemKey: IItemKey | undefined, item: T) => {
  const { label: labelKey, value: valueKey } = itemKey || {}
  if (typeof item === 'string') {
    return {
      curValue: item,
      curLabel: item,
    }
  }
  const { label, value } = item as IOption
  const curLabel = labelKey ? get(item, labelKey) : label
  const curValue = valueKey ? item[valueKey] : value
  return {
    curValue,
    curLabel,
  }
}
const MultipleSelect = <T extends string | IOption>({
  data,
  onChange,
  isMulti,
  label,
  itemKey,
  className,
  defaultValue,
  disabled,
}: Props<T>) => {
  const classes = useStyles()
  const [selects, setSelects] = useState<Array<T>>(defaultValue || [])
  const [results, setResults] = useState<Array<T>>(defaultValue || [])
  const toggleFilter = (item: T) => {
    setSelects((prevState) => {
      let nextSelects: Array<T> = []
      if (isMulti) {
        const index = prevState.findIndex((data) => {
          const { curValue } = covertShowData(itemKey, item)
          const { curValue: curDataValue } = covertShowData(itemKey, data)
          return curDataValue === curValue
        })
        if (index > -1) {
          prevState.splice(index, 1)
          nextSelects = [...prevState]
        } else {
          prevState.push(item)
          nextSelects = [...prevState]
        }
      } else {
        nextSelects = item ? [item] : []
      }
      !isMulti && onChange?.(nextSelects)
      return nextSelects
    })
  }
  const onCancel = () => {
    setSelects([...results])
  }
  const onConfirm = () => {
    setResults([...selects])
    onChange?.([...selects])
  }
  const clearList = isMulti ? results : selects
  return (
    <div className={classes.container}>
      <PopupState variant="popover" popupId="filterPopover">
        {(popupState) => (
          <>
            <TextField
              variant="outlined"
              label={label}
              autoComplete="off"
              disabled={disabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ExpandMore />
                  </InputAdornment>
                ),
              }}
              value={clearList
                ?.map((item) => {
                  const { curLabel } = covertShowData(itemKey, item)
                  return curLabel
                })
                .join(' 、')}
              className={classnames(classes.filter, className)}
              {...bindToggle(popupState)}
            />
            {(data || []).length > 0 && (
              <HoverPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                classes={{ paper: classes.hoverPaper }}
              >
                {data?.map((item, index) => {
                  const { curLabel, curValue } = covertShowData(itemKey, item)
                  return (
                    <FilterItem
                      toggleFilter={() => {
                        !isMulti && popupState.close()
                        toggleFilter(item)
                      }}
                      isSelected={
                        selects.findIndex((item) => {
                          const { curValue: curSelectValue } = covertShowData(itemKey, item)
                          return curValue === curSelectValue
                        }) > -1
                      }
                      label={curLabel}
                      key={`${curLabel}${curValue}`}
                      value={curValue}
                    />
                  )
                })}
                {isMulti && (
                  <div className={classes.buttonGroup}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        popupState.close()
                        onCancel()
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        popupState.close()
                        onConfirm()
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                )}
              </HoverPopover>
            )}
          </>
        )}
      </PopupState>
    </div>
  )
}

export default MultipleSelect
