import React, { useEffect, useRef } from 'react'
import { Box, Typography, TextField, Autocomplete, InputAdornment, CircularProgress } from '@mui/material'
import { getEquipmentList, getEquipmentTrends, getEquipmentTypes } from '@api/index'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import useSetState from '@hooks/useSetState'
import TableList, { TableConfig } from '@component/Tabs/TableList'
import { ExpandMore, Search, North, South, Close } from '@mui/icons-material'
import { ROUND_EQUIPMENT_TYPE } from '@constant/qrcode'
import CustomDateRange from '@component/DateSelect/CustomDateRange'
import { DEFAULT_DATE_RANGE_YTD } from '@/global'
import { CustomI18NITF } from '@activate-inc/activate-ui/dist/types/common.types'

const TREND_CONFIG: Array<TableConfig<any>> = [
  {
    label: 'Date',
    source: 'SubmitDate',
    isDate: true,
    formatKey: 'keyboardDate',
  },
  {
    label: 'Reading',
    source: 'Value',
    align: 'left',
  },
  {
    label: 'Trend',
    source: 'Trend',
    align: 'left',
    convertItem: (item) => {
      const { Trend = 0 } = item
      let color = '#1C1B1F'
      if (Trend > 0) color = '#018786'
      if (Trend < 0) color = '#E04F4F'
      return (
        <span style={{ color, display: 'flex', alignItems: 'center' }}>
          {Trend > 0 && (
            <>
              <North htmlColor={color} style={{ fontSize: 14 }} /> +
            </>
          )}
          {Trend < 0 && (
            <>
              <South htmlColor={color} style={{ fontSize: 14 }} /> -
            </>
          )}
          {Math.abs(Trend)}
        </span>
      )
    },
  },
  {
    label: 'Error',
    source: 'ErrorReport',
    align: 'left',
  },
  {
    label: 'Engineer',
    source: 'EngineerName',
    align: 'left',
  },
]

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: 1400,
    backgroundColor: '#F5F5F5',
    flex: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    padding: '18px 0px 18px',
    marginTop: -98,
  },
  tableHeader: {
    backgroundColor: '#F0F6FC',
    whiteSpace: 'pre-wrap',
  },
  card: {
    margin: '25px 0 0',
    borderRadius: 8,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    padding: '24px 24px 36px',
    backgroundColor: 'white',
  },
  title: {
    fontSize: 20,
    lineHeight: '26px',
    color: '#466CB5',
    marginTop: 24,
    borderBottom: '1px solid #BCBCBC',
    padding: '24px 0 12px',
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  rootClass: {
    display: 'grid',
  },
  date: {
    fontSize: 16,
    paddingRight: '16px !important',
  },
  cardTitle: {
    color: '#211F1F',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    marginBottom: '24px',
  },
  select: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    backgroundColor: '#FFF',
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    borderRadius: 8,
  },
  empty: {
    display: 'flex',
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF',
    marginTop: 24,
  },
}))

interface TypeITF {
  DisplayText: CustomI18NITF
  Type: string
}
const EquipmentTrend = () => {
  const classes = useStyles()
  const { selectedGroupId: gId, selectedPropertyId: bId } = useSelector((state) => state.profile)

  const [{ EquipmentType, trends, EquipmentId, st, equipmentList, loading, range, equipments }, setState] = useSetState(
    {
      trends: [],
      equipments: [] as TypeITF[],
      EquipmentType: '',
      st: '',
      EquipmentId: '',
      loading: false,
      equipmentList: [] as any[],
      range: DEFAULT_DATE_RANGE_YTD,
    }
  )
  const timerRef = useRef<NodeJS.Timeout>()

  const onSearchChange = (e: React.SyntheticEvent, value: string, reason: string) => {
    if (reason === 'reset') return

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      setState({ st: value })
    }, 3000)
  }

  useEffect(() => {
    setState({ loading: true })
    getEquipmentList({ bId, gId, type: EquipmentType, st, take: 10000, skip: 0 })
      .then((res: any) => {
        setState({ equipmentList: res.Equipments })
      })
      .finally(() => {
        setState({ loading: false })
      })
  }, [bId, gId, setState, EquipmentType, st])
  useEffect(() => {
    getEquipmentTypes<{ Types: TypeITF[] }>({ bId, gId }).then((res) => {
      setState({ equipments: res.Types || [] })
    })
  }, [])
  useEffect(() => {
    if (!EquipmentId) return
    const params = { eId: EquipmentId }
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    getEquipmentTrends(params).then((res: any) => {
      setState({ trends: res?.Trends || [] })
    })
  }, [EquipmentId, range, setState])

  return (
    <Box className={classes.paper}>
      <div className={classes.headerRight}>
        <Autocomplete
          value={equipments.find((v) => v.Type === EquipmentType) || null}
          onChange={(e, newValue) => setState({ EquipmentType: newValue?.Type || '', st: '' })}
          options={equipments}
          style={{ marginRight: 16, width: 227 }}
          isOptionEqualToValue={(option, value) => option?.Type === value?.Type}
          getOptionLabel={(option) => option.DisplayText?.en || ''}
          renderInput={(params) => <TextField {...params} label="Equipment Type" />}
          popupIcon={<ExpandMore />}
          classes={{ inputRoot: classes.date }}
        />
        <Autocomplete
          value={equipmentList.find((v) => v.EquipmentId === EquipmentId) || null}
          options={equipmentList}
          isOptionEqualToValue={(option, value) => option?.EquipmentId === value?.EquipmentId}
          getOptionLabel={(option) => option.EquipmentName || ''}
          onChange={(e, newValue) => setState({ EquipmentId: newValue?.EquipmentId || '' })}
          style={{ width: 227 }}
          classes={{
            inputRoot: classes.date,
          }}
          loading={loading}
          onInputChange={onSearchChange}
          popupIcon={loading ? <CircularProgress color="inherit" size={20} /> : <ExpandMore />}
          renderInput={(params) => <TextField {...params} label="Equipment" />}
        />
        <CustomDateRange
          style={{ marginLeft: '16px' }}
          value={range}
          onChange={(value) => setState({ range: value })}
          label="Date Filter"
        />
      </div>

      {EquipmentId ? (
        <>
          <Typography className={classes.title}>Equipment Trend</Typography>
          {trends.map((item, i) => {
            const { Question = '', List = [] } = item
            return (
              <Box className={classes.card} key={i}>
                <Typography className={classes.cardTitle}>{Question}</Typography>
                <TableList
                  config={TREND_CONFIG}
                  data={List || []}
                  rootClass={classes.rootClass}
                  tableLayout="fixed"
                  headerClass={classes.tableHeader}
                />
              </Box>
            )
          })}
          {trends.length === 0 && <Typography className={classes.empty}>No Data</Typography>}
        </>
      ) : (
        <Typography className={classes.select}>Please select equipment</Typography>
      )}
    </Box>
  )
}

export default EquipmentTrend
