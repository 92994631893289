import React, { lazy } from 'react'
import { CustomRoutes } from 'react-admin'
import { Route } from 'react-router-dom'
import Configuration from './configuration/Configuration'
import AccountDetail from './account/AccountDetail'
import PropertyAdd from './account/PropertyAdd'
import AdminSearch from './dashboard/AdminSearch'
import Overall from './analytics/feedback/Overall'
import GlobalReport from './analytics/feedback/GlobalReport'
import ScanReport from './analytics/scan'
import SubscriptionReport from './analytics/report/SubscriptionReport'
import ImportQRCode from './qrcodes/ImportQRCode'
import PropertyQrGenerator from './qrcodes/PropertyQrGenerator'
import ReportBathroomService from './analytics/feedback/BathroomService'
import RoundsEngine from '@pages/roundsEngine'
import RoundsEngineBuilder from '@pages/roundsEngine/builder'
import ServiceRequestReport from '@analytics/tenant/ServiceRequestReport'
import CustomerFeedbackReport from '@analytics/feedback/CustomerFeedbackReport'
import Reset from '@/layout/Reset'
import CustomerPortal from './qrcodes/CustomerPortal'
import Notifications from '@/pages/notifications/Notifications'
import ContentSurvey from '@/pages/survey/ContentSurvey'
import PrintProof from '@pages/print/PrintProof'
import TwoQREdit from '@pages/twoQRLibrary/TwoQREdit'
import InAppFeedbackReport from '@pages/dashboardReport/InAppFeedbackReport'
import PMLibraryDashboard from '@pages/dashboardReport/PMLibraryDashboard'
import SignProof from '@pages/digitalProof/SignProof'
import SurveyList from '@pages/survey/SurveyList'
import SurveyBuilder from '@pages/survey/SurveyBuilder'
import MasterNotepad from '@pages/dashboardReport/MasterNotepad'
import StaffCheckIn from '@pages/dashboardReport/StaffCheckIn'
import UpsellMatrixReport from '@pages/dashboardReport/UpsellMatrixReport'
import DataArchive from '@pages/DataArchive'
import PdfGoogleReviewReport from '@pages/pdf/PdfGoogleReviewReport'
import PropertyCharacteristicsIndex from '@pages/dashboardReport/PropertyCharacteristicsIndex'
import ResearchTest from '@pages/ResearchTest'
import EquipmentTrend from '@pages/dashboardReport/EquipmentTrend'
import PortfolioRoundAnalytics from '@analytics/round/PortfolioRoundAnalytics'
import JobReport from '@pages/dashboardReport/JobReport'
import ResidentFeedback from '@pages/dashboardReport/ResidentFeedback'
const QRCodeEdit = lazy(() => import('./qrcodes/QRCodeEdit'))
const PdfBuilderSolution = lazy(() => import('@pages/pdf/PdfBuilderSolution'))
const PdfSocialReputation = lazy(() => import('@pages/pdf/PdfSocialReputation'))
const PdfProofReport = lazy(() => import('@pages/pdf/PdfProofReport'))
const MenuTabs = lazy(() => import('@pages/MenuTabs'))
const SupplierCheckInReport = lazy(() => import('@pages/SupplierCheckInReport'))
const Feedback = lazy(() => import('./analytics/feedback'))
const InspectionReportDashboard = lazy(() => import('@analytics/inspection/InspectionReport'))
const TenantExperience = lazy(() => import('@analytics/tenant/TenantExperience'))
const SupplierSLAReport = lazy(() => import('@analytics/risk/SupplierSLAReport'))
const RoundAnalytics = lazy(() => import('@analytics/round/RoundAnalytics'))
const Dashboard = lazy(() => import('@/pages/Dashboard'))
const AccountCreate = lazy(() => import('./account/AccountCreate'))
const InspectionReport = lazy(() => import('./analytics/feedback/InspectionReport'))
const Reports = lazy(() => import('@/pages/Reports'))
const CustomerSurvey = lazy(() => import('@/pages/CustomerSurvey'))
const VerifySurvey = lazy(() => import('@/pages/survey/VerifySurvey'))
const ContentCollection = lazy(() => import('@pages/survey/ContentCollection'))
const SurveyWelcome = lazy(() => import('@pages/survey/SurveyWelcom'))
const DigitalProofWelcome = lazy(() => import('@pages/digitalProof/DigitalProofWelcome'))
const DigitalProofCarousel = lazy(() => import('@pages/digitalProof/DigitalProofCarousel'))
const UpdateCOI = lazy(() => import('@pages/coi/updateCOI'))
const ReviewCOI = lazy(() => import('@pages/coi/reviewCOI'))
const BathroomServiceDashboard = lazy(() => import('@pages/dashboardReport/BathroomServiceDashboard'))
const PreventativeMaintenance = lazy(() => import('@pages/dashboardReport/PreventativeMaintenance'))
const PdfRoundAnalytics = lazy(() => import('@pages/dashboardReport/PdfRoundAnalytics'))
const PinActivity = lazy(() => import('@analytics/scan/PinActivity'))
const PortfolioSecurity = lazy(() => import('@pages/dashboardReport/PortfolioSecurity'))
const EquipmentLookup = lazy(() => import('@pages/dashboardReport/EquipmentLookup'))
const PdfRoundDetail = lazy(() => import('@pages/dashboardReport/PdfRoundDetail'))
const GoogleRatingReport = lazy(() => import('@analytics/report/GoogleRatingReport'))
const PdfIncidentReport = lazy(() => import('@pages/dashboardReport/PdfIncidentReport'))
const ReportBuilder = lazy(() => import('@pages/pdf/ReportBuilder'))
const PdfReportBuilder = lazy(() => import('@pages/pdf/PdfReportBuilder'))
const PdfWeeklyException = lazy(() => import('@pages/pdf/PdfWeeklyException'))
const PdfLegal = lazy(() => import('@pages/pdf/PdfLegal'))
const PdfCharacteristics = lazy(() => import('@pages/pdf/PdfCharacteristics'))
const ResidentLog = lazy(() => import('@pages/dashboardReport/ResidentLog'))
const GuestLog = lazy(() => import('@pages/dashboardReport/GuestLog'))
const PdfMechanicalSystem = lazy(() => import('@pages/pdf/PdfMechanicalSystem'))
const PdfChiefEngineer = lazy(() => import('@pages/pdf/PdfChiefEngineer'))
const PdfWorkLetter = lazy(() => import('@pages/pdf/PdfWorkLetter'))
const PdfMobileEmployee = lazy(() => import('@pages/pdf/PdfMobileEmployee'))
const PortfolioMobileEmployee = lazy(() => import('@pages/dashboardReport/PortfolioMobileEmployee'))

const QRCodeList = lazy(() => import('./qrcodes/myqrcode/QRCodeList'))
const TwoQRLibrary = lazy(() => import('@pages/twoQRLibrary/TwoQRLibrary'))
const InspectionLibrary = lazy(() => import('@pages/inspectionLibrary'))
const InspectionReportGlobal = lazy(() => import('@analytics/feedback/InspectionReport'))
const InsuranceDashboard = lazy(() => import('@pages/dashboardReport/InsuranceDashboard'))
const InstallationRound = lazy(() => import('@pages/analytics/InstallationRound'))
const TenantLibrary = lazy(() => import('@pages/tenant/TenantLibrary'))
const ResidentsLibrary = lazy(() => import('@pages/residents/ResidentsLibrary'))
const DigitalProofReport = lazy(() => import('@pages/digitalProof/DigitalProofReport'))
const PMLibrary = lazy(() => import('@pages/pm/PMLibrary'))
const EditPMLibrary = lazy(() => import('@pages/pm/EditPMLibrary'))
const VacancyLead = lazy(() => import('@pages/dashboardReport/VacancyLead'))
const LeadCapture = lazy(() => import('@pages/dashboardReport/LeadCapture'))
const SubLeadCapture = lazy(() => import('@pages/dashboardReport/SubLeadCapture'))
const MechanicalSpaceLibrary = lazy(() => import('@pages/MechanicalSpaceLibrary'))
const RenewalReport = lazy(() => import('@pages/dashboardReport/RenewalReport'))
const SubscriptionList = lazy(() => import('@pages/settings/SubscriptionList'))
const ContactListReport = lazy(() => import('@/account/ContactList'))
const PortfolioTeam = lazy(() => import('@/account/ContactList').then((module) => ({ default: module.PortfolioTeam })))
const VendorList = lazy(() => import('@/account/VendorList'))
const EquipmentLibrary = lazy(() => import('@pages/roundsEngine/equipment/EquipmentLibrary'))
const EngineeringRoundsList = lazy(() => import('@pages/roundsEngine/table/EngineeringRoundsTable'))
const MoveOutReport = lazy(() => import('@pages/dashboardReport/MoveOutReport'))
const PrintLibrary = lazy(() => import('@pages/print/PrintLibrary'))
const PrintHubLibrary = lazy(() => import('@pages/print/PrintHubLibrary'))
const PrintQRLibrary = lazy(() => import('@pages/print/PrintQRLibrary'))
const ReportBuilderSubscriptions = lazy(() => import('@pages/pdf/ReportBuilderSubscriptions'))
const Legal = lazy(() => import('@pages/Legal'))
const FeedbackEngine = lazy(() => import('@pages/settings/FeedbackEngine'))

const Router = [
  <CustomRoutes key="/configuration">
    <Route path="/configuration" element={<Configuration />} />
  </CustomRoutes>,
  <CustomRoutes noLayout key="/adminSearch">
    <Route path="/adminSearch" element={<AdminSearch />} />
  </CustomRoutes>,
  <CustomRoutes key="/accountDetail">
    <Route path="/accountDetail" element={<AccountDetail />} />
  </CustomRoutes>,
  // <CustomRoutes key="/qrcode/:groupId/:bId/:qrId">
  //   <Route path="/qrcode/:groupId/:bId/:qrId?" element={<QRCodeEdit />} />
  // </CustomRoutes>,
  <CustomRoutes key="/feedbackOverall">
    <Route path="/feedbackOverall/:bId/:et?" element={<Overall />} />
  </CustomRoutes>,
  <CustomRoutes key="/globalReport">
    <Route path="/globalReport" element={<GlobalReport />} />
  </CustomRoutes>,
  <CustomRoutes key="/reportGlobalReport">
    <Route path="/reportGlobalReport" element={<GlobalReport />} />
  </CustomRoutes>,
  <CustomRoutes key="/importQRCode">
    <Route path="/importQRCode" element={<ImportQRCode />} />
  </CustomRoutes>,
  <CustomRoutes key="/propertyQrGenerator">
    <Route path="/propertyQrGenerator/:fromQr?" element={<PropertyQrGenerator />} />
  </CustomRoutes>,
  <CustomRoutes noLayout key="/report">
    <Route path="/report/:type/:rId" element={<SubscriptionReport />} />
  </CustomRoutes>,
  <CustomRoutes key="/propertyAdd">
    <Route path="/propertyAdd/:bId?/:idx?" element={<PropertyAdd />} />
  </CustomRoutes>,
  <CustomRoutes key="/reportBathroomService">
    <Route path="/reportBathroomService/:bId/:eId?" element={<ReportBathroomService />} />
  </CustomRoutes>,
  <CustomRoutes key="/roundsEngineBuilder">
    <Route path="/roundsEngineBuilder/:type?/:id?" element={<RoundsEngineBuilder />} />
  </CustomRoutes>,
  <CustomRoutes key="/serviceRequestPortfolio">
    <Route path="/serviceRequestPortfolio" element={<ServiceRequestReport />} />
  </CustomRoutes>,
  <CustomRoutes key="/activateReport">
    <Route path="/activateReport" element={<CustomerFeedbackReport />} />
  </CustomRoutes>,
  <CustomRoutes key="/activateReportGroup">
    <Route path="/activateReport/:gId" element={<CustomerFeedbackReport key="activateReportGroup" />} />
  </CustomRoutes>,
  <CustomRoutes key="/activateReportProperty">
    <Route path="/activateReport/:gId/:bId" element={<CustomerFeedbackReport key="activateReportProperty" />} />
  </CustomRoutes>,
  <CustomRoutes noLayout key="/reset">
    <Route path="/reset" element={<Reset />} />
  </CustomRoutes>,
  <CustomRoutes key="/customerPortal">
    <Route path="/customerPortal" element={<CustomerPortal />} />
  </CustomRoutes>,
  <CustomRoutes key="/contentSurvey">
    <Route path="/contentSurvey" element={<ContentSurvey />} />
  </CustomRoutes>,
  <CustomRoutes key="/contentSurvey">
    <Route path="/contentSurvey/:gId/:bId" element={<ContentSurvey />} />
  </CustomRoutes>,
]

export const lazyRouter: any[] = [
  {
    path: '/qrcode/:groupId/:bId/:qrId?',
    element: <QRCodeEdit />,
  },
  {
    path: '/accountCreate/:gId?',
    noLayout: false,
    element: <AccountCreate />,
  },
  {
    path: 'inspectionReportPortfolio',
    element: <InspectionReport />,
  },
  {
    path: 'customerSurvey',
    element: <CustomerSurvey />,
  },
  {
    path: 'verifySurvey/:gId?/:bId?',
    element: <VerifySurvey />,
  },
  {
    path: 'contentCollection',
    element: <ContentCollection />,
  },
  {
    path: 'portfolioTeam',
    element: <PortfolioTeam />,
  },
  {
    path: '/portfolioAnalytics',
    element: <MenuTabs type="portfolioAnalytics" key="portfolioAnalytics" />,
    child: [
      {
        path: '/portfolioAnalytics/globalDashboard',
        element: <Dashboard key="/globalDashboard" />,
        child: [
          {
            path: '/portfolioAnalytics/globalDashboard/globalReport/:bId?',
            element: <GlobalReport />,
          },
          {
            path: '/portfolioAnalytics/globalDashboard/globalInspectionReport',
            element: <InspectionReportGlobal />,
          },
          {
            path: '/portfolioAnalytics/globalDashboard/globalTenantExperience/:typeIndex?',
            element: <TenantExperience />,
          },
          {
            path: '/portfolioAnalytics/globalDashboard/globalInsuranceDashboard',
            element: <InsuranceDashboard />,
          },
          {
            path: '/portfolioAnalytics/globalDashboard/globalSecurityPatrol',
            element: <PortfolioSecurity />,
          },
          {
            path: '/portfolioAnalytics/globalDashboard/globalPropertyCharacteristicsIndex',
            element: <PropertyCharacteristicsIndex />,
          },
          {
            path: '/portfolioAnalytics/globalDashboard/globalGoogleRatingReport',
            element: <GoogleRatingReport type="portfolioAnalytics" />,
          },
          {
            path: '/portfolioAnalytics/globalDashboard/globalUpsellMatrix',
            element: <UpsellMatrixReport />,
          },
          {
            path: '/portfolioAnalytics/globalDashboard/globalPortfolioRoundAnalytics',
            element: <PortfolioRoundAnalytics />,
          },
          {
            path: '/portfolioAnalytics/globalDashboard/globalMobileEmployee',
            element: <PortfolioMobileEmployee />,
          },
          {
            path: '/portfolioAnalytics/globalDashboard/globalJobReport',
            element: <JobReport />,
          },
          {
            path: '/portfolioAnalytics/globalDashboard/globalResidentFeedback',
            element: <ResidentFeedback />,
          },
        ],
      },
      {
        path: '/portfolioAnalytics/subscriptions',
        element: <SubscriptionList />,
      },
    ],
  },
  {
    path: '/contacts',
    element: <MenuTabs type="contacts" key="contacts" />,
    child: [
      {
        path: '/contacts/contactList',
        element: <ContactListReport />,
      },
      {
        path: '/contacts/tenantLibrary',
        element: <TenantLibrary />,
      },
      {
        path: '/contacts/residentsLibrary',
        element: <ResidentsLibrary />,
      },
      {
        path: '/contacts/vendorList',
        element: <VendorList />,
      },
    ],
  },
  {
    path: '/library',
    element: <MenuTabs type="library" key="library" />,
    child: [
      {
        path: '/library/qrcodelist',
        element: <QRCodeList />,
      },
      {
        path: '/library/twoQRLibrary',
        element: <TwoQRLibrary />,
      },
      {
        path: '/library/inspectionLibrary',
        element: <InspectionLibrary />,
      },
      {
        path: '/library/roundsEngine/:type?',
        element: <RoundsEngine />,
      },
      {
        path: '/library/equipmentLibrary',
        element: <EquipmentLibrary />,
      },
      {
        path: '/library/mechanicalSpaceLibrary',
        element: <MechanicalSpaceLibrary />,
      },
      {
        path: '/library/pmLibrary',
        element: <PMLibrary />,
      },
      {
        path: '/library/activatePMLibrary',
        element: <EngineeringRoundsList isPm />,
      },
    ],
  },
  {
    path: '/analytics',
    element: <MenuTabs type="analytics" key="analytics" />,
    child: [
      {
        path: '/analytics/reports',
        element: <Reports />,
      },
      {
        path: '/analytics/notifications',
        element: <Notifications />,
      },
      {
        path: '/analytics/dashboard',
        element: <Dashboard key="/dashboard" />,
        child: [
          {
            path: '/analytics/dashboard/roundAnalytics/:roundType?',
            element: <RoundAnalytics />,
          },
          // {
          //   path: '/analytics/dashboard/engineeringRounds',
          //   element: <EngineeringRounds />,
          // },
          {
            path: '/analytics/dashboard/supplierSLAReport',
            element: <SupplierSLAReport />,
          },
          {
            path: '/analytics/dashboard/tenantExperience/:typeIndex?',
            element: <TenantExperience />,
          },
          {
            path: '/analytics/dashboard/inspectionReport',
            element: <InspectionReportDashboard />,
          },
          {
            path: '/analytics/dashboard/reportAnalyticsFeedback/:type/:bId?',
            element: <Feedback />,
          },
          {
            path: '/analytics/dashboard/supplierCheckInReport',
            element: <SupplierCheckInReport />,
          },
          {
            path: '/analytics/dashboard/roofAccessAuthorizationReport',
            element: <SupplierCheckInReport type="RoofAccess" />,
          },
          {
            path: '/analytics/dashboard/tenantHVACReport',
            element: <SupplierCheckInReport type="TenantHVAC" />,
          },
          {
            path: '/analytics/dashboard/vacancyLead',
            element: <VacancyLead />,
          },
          {
            path: '/analytics/dashboard/leadCapture/:type',
            element: <LeadCapture />,
          },
          {
            path: '/analytics/dashboard/renewalReport',
            element: <RenewalReport />,
          },
          {
            path: '/analytics/dashboard/inAppFeedbackReport',
            element: <InAppFeedbackReport />,
          },
          {
            path: '/analytics/dashboard/bathroomService',
            element: <BathroomServiceDashboard />,
          },
          {
            path: '/analytics/dashboard/preventativeMaintenance',
            element: <PreventativeMaintenance />,
          },
          {
            path: '/analytics/dashboard/pMLibraryDashboard',
            element: <PMLibraryDashboard />,
          },
          {
            path: '/analytics/dashboard/moveOutReport',
            element: <MoveOutReport />,
          },
          {
            path: '/analytics/dashboard/equipmentLookup',
            element: <EquipmentLookup />,
          },
          {
            path: '/analytics/dashboard/equipmentTrend',
            element: <EquipmentTrend />,
          },
          {
            path: '/analytics/dashboard/masterNotepad',
            element: <MasterNotepad />,
          },
          {
            path: '/analytics/dashboard/staffCheckIn',
            element: <StaffCheckIn />,
          },
          {
            path: '/analytics/dashboard/residentLog',
            element: <ResidentLog />,
          },
          {
            path: '/analytics/dashboard/guestLog',
            element: <GuestLog />,
          },
          {
            path: '/analytics/dashboard/residentFeedback',
            element: <ResidentFeedback isBuilding />,
          },
        ],
      },
      {
        path: '/analytics/subscription',
        element: <SubscriptionList />,
      },
    ],
  },
  {
    path: '/analyticsFeedback/:bId',
    element: <Feedback />,
  },
  {
    path: '/surveyWelcome/:nextPathname?/:bId?',
    element: <SurveyWelcome />,
  },
  {
    path: '/digitalProof',
    element: <DigitalProofWelcome />,
    noLayout: true,
  },
  {
    path: '/signProof',
    element: <SignProof />,
    noLayout: true,
  },
  {
    path: '/digitalProofCarousel',
    element: <DigitalProofCarousel />,
    noLayout: true,
  },
  {
    path: '/editPMLibrary/:woId',
    element: <EditPMLibrary />,
  },
  {
    path: 'subLeadCapture/:type/:eventId',
    element: <SubLeadCapture />,
  },
  {
    path: '/updateCOI/:vendorId',
    element: <UpdateCOI />,
    noLayout: true,
  },
  {
    path: '/reviewCOI/:coiId',
    element: <ReviewCOI />,
    noLayout: true,
  },
  {
    path: '/twoQREdit',
    element: <TwoQREdit />,
  },
  {
    path: '/twoQREdit/:pId',
    element: <TwoQREdit />,
  },
  {
    path: '/pdf/:roundType/:gId/:ptId/:bId/:startDate/:endDate/:title',
    element: <PdfRoundAnalytics />,
    noLayout: true,
  },
  {
    path: '/pdfRoundDetail/:iptId',
    element: <PdfRoundDetail />,
    noLayout: true,
  },
  {
    path: '/pdfGoogleReviewReport/:rId',
    element: <PdfGoogleReviewReport />,
    noLayout: true,
  },
  {
    path: '/pdfSocialReputation/:rId',
    element: <PdfSocialReputation />,
    noLayout: true,
  },
  {
    path: '/pdfIncidentReport/:id',
    element: <PdfIncidentReport />,
    noLayout: true,
  },
  {
    path: '/pdfProofReport/:gId/:bId/:type',
    element: <PdfProofReport />,
    noLayout: true,
  },
  {
    path: '/marketing',
    element: <MenuTabs type="marketing" key="marketing" />,
    child: [
      {
        path: '/marketing/customerPortal',
        element: <CustomerPortal />,
      },
    ],
  },
  {
    path: '/masterLibrary',
    element: <MenuTabs type="masterLibrary" key="masterLibrary" />,
    child: [
      {
        path: '/masterLibrary/printLibrary',
        element: <PrintLibrary />,
      },
      {
        path: '/masterLibrary/printHubLibrary',
        element: <PrintHubLibrary />,
      },
      {
        path: '/masterLibrary/printQRLibrary',
        element: <PrintQRLibrary />,
      },
    ],
  },
  {
    path: '/scans',
    element: <MenuTabs type="scans" key="scans" />,
    child: [
      {
        path: '/scans/qrLocation',
        element: <ScanReport key="Location" />,
      },
      {
        path: '/scans/qrName',
        element: <ScanReport type="QrName" key="QrName" />,
      },
      {
        path: '/scans/pinActivity',
        element: <PinActivity />,
      },
    ],
  },
  {
    path: '/googleRatingReport',
    element: <GoogleRatingReport type="Activate" />,
  },
  {
    path: '/surveyLibrary',
    element: <MenuTabs type="surveyLibrary" key="surveyLibrary" />,
    child: [
      {
        path: '/surveyLibrary/surveyBuilder',
        element: <SurveyBuilder />,
      },
      {
        path: '/surveyLibrary/surveyBuilder/:gId/:bId',
        element: <SurveyBuilder />,
      },
      {
        path: '/surveyLibrary/surveyList',
        element: <SurveyList />,
      },
    ],
  },
  {
    path: '/reportBuilder',
    element: <MenuTabs type="reportBuilder" key="reportBuilder" />,
    child: [
      {
        path: '/reportBuilder/builder',
        element: <ReportBuilder />,
      },
      {
        path: '/reportBuilder/subscriptions',
        element: <ReportBuilderSubscriptions />,
      },
    ],
  },
  {
    path: '/pdfReportBuilder/:rId',
    element: <PdfReportBuilder />,
    noLayout: true,
  },
  {
    path: '/PdfBuilderSolution/:sId',
    element: <PdfBuilderSolution />,
    noLayout: true,
  },
  {
    path: 'weeklyexception',
    element: <PdfWeeklyException />,
    noLayout: true,
  },
  {
    path: '/pdfLegal/:id',
    element: <PdfLegal />,
    noLayout: true,
  },
  {
    path: '/pdfCharacteristics/:gId/:bId',
    element: <PdfCharacteristics />,
    noLayout: true,
  },
  {
    path: '/pdfMechanicalSystem/:id',
    element: <PdfMechanicalSystem />,
    noLayout: true,
  },
  {
    path: '/pdfChiefEngineer/:rId',
    element: <PdfChiefEngineer />,
    noLayout: true,
  },
  {
    path: '/pdfWorkLetter/:rId',
    element: <PdfWorkLetter />,
    noLayout: true,
  },
  {
    path: '/pdfMobileEmployee/:gId/:bId?/:startDate?/:endDate?',
    element: <PdfMobileEmployee />,
    noLayout: true,
  },
  {
    path: '/feedbackEngine',
    element: <FeedbackEngine />,
  },
  {
    path: '/researchTest',
    element: <ResearchTest />,
    noLayout: true,
  },
]

export const resourceRouter = [
  {
    path: 'installationRound',
    Element: InstallationRound,
  },
  {
    path: 'digitalProofReport',
    Element: DigitalProofReport,
  },
  {
    path: 'printProof',
    Element: PrintProof,
  },
  {
    path: 'legal',
    Element: Legal,
  },
  {
    path: 'dataArchive',
    Element: DataArchive,
  },
]

export default Router
