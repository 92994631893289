import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  container: {},
  title: {
    fontSize: 20,
    color: '#466CB5',
    padding: 12,
    borderBottom: '1px solid #BCBCBC',
    marginBottom: 24,
  },
}))

interface Props {
  title: string
}

const SectionHeader: React.FC<Props> = ({ title, children }) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </Box>
  )
}

export default SectionHeader
