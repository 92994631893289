import React, { useCallback, useEffect } from 'react'
import { Datagrid, List, TextField, Resource, useListContext, useRefresh, useUnselectAll } from 'react-admin'
import { ListProps, TextFieldProps, useRecordContext, TopToolbar } from 'react-admin'
import { Typography, Box, Button, IconButton, TextField as MuiTextField, Tooltip } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { makeStyles } from '@mui/styles'
import useSetState from '@hooks/useSetState'
import { useNavigate } from 'react-router-dom'
import CONFIG from '@constant/config'
import { AccountSearch } from '@/dashboard/AdminSearch'
import { PropertySearch } from '@/dashboard/PropertySearchBar'
import { GroupITF } from '@/types/group'
import { IEntity } from '@/types'
import { copyText } from '@/utils'
import { Clear, Edit, FindInPage, Close } from '@mui/icons-material'
import Images from '@assets/images'
import { copySurveyBuilder } from '@api'

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: '#f5f5f5',
      padding: '0 24px 24px',
      position: 'relative',
      fontFamily: 'Roboto',
    },
    header: {
      padding: '0px 0 24px',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 24,
    },
    textField: {
      width: 200,
      backgroundColor: '#f7f7f7',
    },
    toolbar: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 0,
      marginBottom: 24,
    },
    title: {
      fontWeight: 'bold',
      fontSize: 20,
      color: '#211F1F',
    },
    link: {
      width: 100,
      overflow: 'scroll',
      whiteSpace: 'nowrap',
      textDecoration: 'underline',
      color: '#1976D2',
      cursor: 'pointer',
    },
    action: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: 18,
      background: '#FBFBFB',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: 6,
      marginBottom: 12,
      width: '100%',
      height: 60,
      boxSizing: 'border-box',
    },
    left: {
      flex: 1,
    },
    select: {
      display: 'flex',
      alignItems: 'center',
    },
    modalField: {
      width: '100%',
    },
  }
})

const PrintURL = (props: TextFieldProps & { onDelete?: (value: string) => void; isEdit?: boolean }) => {
  const record = useRecordContext(props)
  const navigate = useNavigate()
  const classesInter = useStyles()
  const { isEdit } = props
  // @ts-ignore
  if (!props.source) return null
  let value = `/surveyLibrary/surveyBuilder/${record.GroupId}/${record.BuildingId}`
  if (!isEdit) {
    value = `/contentSurvey/${record.GroupId}/${record.BuildingId}`
  }
  const onClick = () => {
    if (isEdit) {
      navigate(value, { replace: true })
      return
    } else {
      navigate(value, { state: { isResponses: true } })
    }
  }
  return (
    <Typography className={classesInter.link} onClick={onClick}>
      {`${CONFIG.adminHost}${value}`}
    </Typography>
  )
}

const Actions: React.FC = () => {
  const classes = useStyles()
  const { selectedIds = [], data = [], resource } = useListContext()
  const isOne = selectedIds.length === 1
  const isSelect = selectedIds.length >= 1
  const refresh = useRefresh()
  const unselectAll = useUnselectAll(resource)
  const navigate = useNavigate()
  const [{ showModal, BuilderId, gId, bId, SurveyName }, setState] = useSetState({
    showModal: false,
    BuilderId: '',
    gId: '',
    bId: '',
    SurveyName: '',
  })

  const onEdit = () => {
    const id = selectedIds[0]
    const record = data.find((item) => item.id === id)
    navigate(`/surveyLibrary/surveyBuilder/${record.GroupId}/${record.BuildingId}`, { replace: true })
  }
  const linkToViewResponses = () => {
    const id = selectedIds[0]
    const record = data.find((item) => item.id === id)
    navigate(`/contentSurvey/${record.GroupId}/${record.BuildingId}`, { state: { isResponses: true } })
  }
  const onCopy = () => {
    const id = selectedIds[0]
    const record = data.find((item) => item.id === id)
    setState({ showModal: true, BuilderId: record.BuilderId })
  }
  const onCopySave = () => {
    copySurveyBuilder({ BuilderId, DestGroupId: gId, DestBuildingId: bId, SurveyName }).then(() => {
      setState({ showModal: false })
      refresh()
    })
  }
  const onCopyText = () => {
    const id = selectedIds[0]
    const record = data.find((item) => item.id === id)
    if (record.Status !== 'Completed') return ''
    const link = `${CONFIG.adminHost}/surveyWelcome/contentSurvey/${record.BuildingId}`
    copyText(link)
  }
  let canCopyText = false
  if (isOne) {
    const id = selectedIds[0]
    const record = data?.find((item) => item.id === id)
    canCopyText = record?.Status === 'Completed'
  }
  const unselect = () => {
    unselectAll()
  }
  useEffect(() => {
    unselectAll()
  }, [])

  return (
    <TopToolbar className={classes.toolbar}>
      {!isSelect && <Typography className={classes.title}>Survey List</Typography>}
      <Box className={classes.action} style={{ display: isSelect ? '' : 'none' }}>
        <Box className={classes.left}>
          <Box className={classes.select}>
            <IconButton onClick={unselect}>
              <Clear htmlColor="#211F1F" style={{ fontSize: 20 }} />
            </IconButton>
            <Typography style={{ flex: 1 }}>{selectedIds.length} items selected</Typography>
          </Box>
        </Box>
        {isOne && (
          <span style={{ cursor: 'pointer' }}>
            <Tooltip title={'Edit Survey'}>
              <Edit onClick={onEdit} color="primary" sx={{ fontSize: '24px' }} />
            </Tooltip>
            <Tooltip title={'View Survey Responses'}>
              <FindInPage sx={{ margin: '0 8px', fontSize: '24px' }} onClick={linkToViewResponses} color="primary" />
            </Tooltip>
            <Tooltip title={'Copy Survey'}>
              <img src={Images.clone} alt="" onClick={onCopy} />
            </Tooltip>
            {canCopyText && (
              <Tooltip title={'Copy Survey Link'}>
                <img src={Images.iconLink} alt="" onClick={onCopyText} style={{ marginLeft: '8px' }} />
              </Tooltip>
            )}
          </span>
        )}
      </Box>

      <Dialog open={showModal} disableEscapeKeyDown maxWidth="md" onClick={(e) => e.stopPropagation()}>
        <DialogTitle id="scroll-dialog-title">
          <Box sx={{ width: '500px', display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ color: '#211F1F', fontSize: '24px', fontWeight: '600', flex: 1 }}>Copy Survey</Typography>
            <IconButton onClick={() => setState({ showModal: false })} style={{ padding: 0 }}>
              <Close htmlColor="#211F1F" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <AccountSearch
            label="Account"
            onChange={(account) => setState({ gId: account.acId, bId: '' })}
            gId={gId || ''}
            inputRootClass={classes.modalField}
            style={{ marginTop: 16 }}
          />
          <PropertySearch
            label="Building"
            gId={gId || ''}
            bId={bId || ''}
            onChange={(property) => setState({ bId: property.id })}
            inputRootClass={classes.modalField}
            style={{ marginTop: 24 }}
          />
          <MuiTextField
            label="Survey Name"
            value={SurveyName}
            onChange={(e) => setState({ SurveyName: e.target.value })}
            fullWidth
            sx={{ marginTop: '24px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setState({ showModal: false })} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onCopySave}
            color="primary"
            variant="contained"
            sx={{ margin: '0 16px 8px 0' }}
            disabled={!gId || !bId || !SurveyName}
          >
            Confirmation
          </Button>
        </DialogActions>
      </Dialog>
    </TopToolbar>
  )
}
const SurveyDataList = (props: ListProps) => {
  const classes = useStyles()
  const [data, setData] = useSetState<{ gId?: string; bId?: string }>({})
  const { gId, bId } = data
  const onChangeAccount = useCallback(
    (account: GroupITF) => {
      setData({ gId: account.acId, bId: '' })
    },
    [setData]
  )

  const onChangeProperty = useCallback(
    (property: IEntity) => {
      setData({ bId: property.id })
    },
    [setData]
  )
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <AccountSearch label="Account" onChange={onChangeAccount} gId={gId || ''} inputRootClass={classes.textField} />
        <PropertySearch
          label="Building"
          gId={gId || ''}
          bId={bId || ''}
          onChange={onChangeProperty}
          inputRootClass={classes.textField}
        />
      </Box>
      <List {...props} filter={{ gId, bId }} empty={false} perPage={50} actions={<Actions />}>
        <Datagrid optimized>
          <TextField source="GroupName.en" label="Account" noWrap sortable={false} />
          <TextField source="BuildingName.en" label="Property" noWrap sortable={false} />
          <TextField source="Status" label="Status" noWrap sortable={false} />
          <TextField source="SurveyName" label="Survey Name" noWrap sortable={false} />
          {/* <PrintURL sortable={false} source="CMSUrl" label="Link to edit" isEdit />
          <PrintURL sortable={false} source="CMSUrl" label="Link to view responses" />
          <FunctionField
            sortable={false}
            source="Status"
            label="Customer Link"
            render={(record) => {
              const link = `${CONFIG.adminHost}/surveyWelcome/contentSurvey/${record.BuildingId}`
              if (record.Status !== 'Completed') return ''
              return (
                <Button variant="contained" onClick={() => copyText(link)}>
                  COPY
                </Button>
              )
            }} 
          /> */}
        </Datagrid>
      </List>
    </Box>
  )
}

const SurveyList = () => {
  return <Resource name="surveyList" list={SurveyDataList} />
}

export default SurveyList
