import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { DEFAULT_DATE_RANGE_TODAY } from '@/global'
import { getComplianceOverview, getPropertyList } from '@api'
import { RoundComplianceOverview, RoundSummary as RoundSummaryITF } from '@/types/analytics'
import RoundCard from '@component/Card/RoundCard'
import { formatNumber } from '@utils/common'
import Images from '@assets/images'
import SectionHeader from '@component/Header/SectionHeader'
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material'
import RoundExceptions from '@component/Round/RoundExceptions'
import { TableConfig } from '@component/Tabs/TableList'
import PhotoTip from '@component/Round/PhotoTip'
import { useParams } from 'react-router-dom'
import CustomDateRange from '@component/DateSelect/CustomDateRange'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'
import { ExpandMore } from '@mui/icons-material'
import { PropertyInfo } from '@/types/entity'

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#F5F5F5',
    flex: 1,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: -100,
  },
  header: {
    display: 'flex',
    padding: '18px 0',
    justifyContent: 'flex-end',
  },
  row: {
    display: 'flex',
  },
  top1: {
    minWidth: 360,
    marginBottom: 32,
    marginRight: 24,
  },
  card: {
    borderRadius: 8,
    marginBottom: 24,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.1)',
    padding: '24px 24px 36px',
    backgroundColor: 'white',
  },
  loading: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  select: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    backgroundColor: '#FFF',
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    borderRadius: 8,
  },
}))

const EXCEPTION_CONFIG: Array<TableConfig<RoundSummaryITF>> = [
  {
    label: 'Date',
    formatKey: 'keyboardDate',
    source: 'SubmitDate',
    isDate: true,
    sort: true,
    width: 100,
  },
  {
    label: 'Time',
    formatKey: 'fullTime12h',
    source: 'SubmitDate',
    isDate: true,
    sort: true,
    width: 120,
  },
  {
    label: 'Floor',
    source: 'Floor',
    sort: true,
    width: 189,
  },
  {
    label: 'Bathroom Type',
    source: 'Location',
    sort: true,
    width: 189,
  },

  {
    label: 'Error Reason',
    source: 'ErrorReason',
    sort: true,
    width: 140,
  },
  {
    label: 'Question',
    source: 'Question',
    sort: true,
    width: 220,
  },
  {
    label: 'Error',
    source: 'ErrorReport',
    sort: true,
    width: 220,
  },
  {
    label: 'Cleaner Name',
    source: 'InspectorName',
    sort: true,
    width: 150,
  },
  {
    label: 'Photo',
    source: 'PhotoUrl',
    width: 140,
    convertItem: (item) => <PhotoTip photo={item.PhotoUrl} reason={item.ErrorReason} />,
  },
]

interface RoundContentProps {
  gId: string
  bId: string
  range?: DateRange<Dayjs>
  rType: string
  ptId?: string
}
export const RoundContent: React.FC<RoundContentProps> = ({ gId, bId, range, rType, ptId = '' }) => {
  const classes = useStyles()
  const [overview, setOverview] = useState<RoundComplianceOverview>()
  const [loading, setLoading] = useState(false)
  const getData = useCallback(async () => {
    setLoading(true)
    const params = {
      gId,
      bId: bId,
      rt: rType,
      ptId,
    }
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    const result = await getComplianceOverview<RoundComplianceOverview>(params)
    setLoading(false)
    setOverview(result)
  }, [gId, bId, range, rType, ptId])
  useEffect(() => {
    getData()
  }, [getData])

  return (
    <>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress size={100} />
        </div>
      )}
      <div className={classes.row}>
        <RoundCard
          className={classes.top1}
          isPercent
          ceil
          value={overview?.RoundCompliance}
          label="Rounds"
          desc={`${formatNumber(overview?.ActualRoundInspectionNumber)} of ${formatNumber(
            overview?.RequiredRoundInspectionNumber
          )}`}
          loading={!overview}
        />
        <RoundCard
          className={classes.top1}
          isPercent
          ceil
          label="Stops"
          loading={!overview}
          desc={`${formatNumber(overview?.ActualStopCompleteNumber)} of ${formatNumber(
            overview?.RequiredStopInspectionNumber
          )}`}
          value={overview?.StopCompliance}
        />
        <RoundCard
          className={classes.top1}
          label="Measurement"
          icon={Images.measurement}
          value={formatNumber(overview?.MeasurementNumber)}
          loading={!overview}
        />
        <RoundCard
          className={classes.top1}
          label="Tolerance Errors"
          loading={!overview}
          value={formatNumber(overview?.StopsErrorNumber)}
          icon={Images.iconWarn}
        />
      </div>
      <SectionHeader title="Round Exceptions">
        <Box className={classes.card}>
          <RoundExceptions
            emptyText="No exceptions"
            config={EXCEPTION_CONFIG}
            gId={gId}
            bId={bId}
            range={range}
            rType={rType}
            ptId={ptId}
          />
        </Box>
      </SectionHeader>
    </>
  )
}
const PortfolioRoundAnalytics: React.FC = () => {
  const classes = useStyles()
  const { selectedGroupId: gId } = useSelector((state) => state.profile)
  const [range, setRange] = useState<any>(DEFAULT_DATE_RANGE_TODAY)
  const [bIdInfo, setBIdInfo] = useState<{ bId?: string; ptId?: string }>()
  const { bId, ptId } = bIdInfo || {}
  const [propertyList, setPropertyList] = useState<PropertyInfo[]>()

  useEffect(() => {
    localStorage.setItem(
      'DashboardDate',
      JSON.stringify({
        StartDate: range[0].valueOf(),
        EndDate: range[1].valueOf(),
      })
    )
  }, [range])
  useEffect(() => {
    if (!gId) return
    getPropertyList<{ Properties: PropertyInfo[] }>({
      filter: JSON.stringify({ gId }),
      pagination: JSON.stringify({ page: 0, perPage: 999 }),
    }).then((res: any) => {
      setPropertyList(res.Properties)
    })
  }, [gId])
  return (
    <div className={classes.paper}>
      <div className={classes.header}>
        <Autocomplete
          value={propertyList?.find((v) => v.id === bId) || null}
          onChange={(e, newValue) => {
            setBIdInfo({ bId: newValue?.id || '', ptId: newValue?.PropertyTag?.PropertyTagId })
          }}
          options={propertyList || []}
          loading={!propertyList}
          style={{ marginRight: 16, width: 227 }}
          isOptionEqualToValue={(option, value) => option?.Type === value?.Type}
          getOptionLabel={(option) => option.Name?.en || ''}
          renderInput={(params) => <TextField {...params} label="Building" />}
          popupIcon={<ExpandMore />}
          // classes={{ inputRoot: classes.date }}
        />
        <CustomDateRange value={range} onChange={(value) => setRange(value)} label="Date Range" />
      </div>
      {bId ? (
        <RoundContent gId={gId} bId={bId} range={range} rType="Cleaning" ptId={ptId} />
      ) : (
        <Typography className={classes.select}>Please select Building</Typography>
      )}
    </div>
  )
}

export default PortfolioRoundAnalytics
