import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import EditAndSave from '@component/Button/EditAndSave'

interface Information {
  name: string
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  value?: string | Record<string, string>[]
}

const sourceData: Information[] = [
  // { name: 'General', label: 'QR General Pin', required: true },
  { name: 'ChiefEngineer', label: 'Chief Engineer', required: false },
  { name: 'GM', label: 'GM', required: false },
  { name: 'Cleaning', label: 'Cleaning', required: false },
  { name: 'Security', label: 'Security', required: false },
  { name: 'Supplier', label: 'Supplier', required: false },
  { name: 'MobileEngineer', label: 'Mobile Engineer', required: false },
]

const useStyles = makeStyles(() => {
  return {
    content: {
      backgroundColor: '#fff',
    },
    information: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    textField: {
      minWidth: 230,
      flex: '20%',
      margin: '0 12px 24px',
    },
    empty: {
      height: 0,
      margin: '0 12px',
    },
    shrink: {
      backgroundColor: '#fff',
      padding: '0 5px',
    },
    asterisk: {
      color: '#ff0000',
    },
    select: {
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
    timePicker: {
      display: 'flex',
    },
    timePickerItem: {
      flex: 1,
      '&:first-child': {
        marginRight: 10,
      },
    },
    timePickerRoot: {
      paddingRight: '24px !important',
    },
    dateInput: {
      padding: '0px 5px 0 4px !important',
    },
    popupIndicator: {
      transform: 'rotate(0deg)',
    },
    timePickerLabel: {
      transform: 'translate(14px, 8px) scale(1)',
    },
    timePickerLabelShrink: {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
  }
})

interface Props {
  validate: boolean
  informationRef: React.MutableRefObject<{ informationData: Information[] }>
  isEdit: boolean
  editData: any
  disabled?: boolean
  onSave: () => void
}
const PropertyPIN = (props: Props) => {
  const { informationRef, isEdit, editData, onSave, disabled } = props
  const classes = useStyles()
  const [onlyView, setOnlyView] = useState(isEdit)

  const [, setRender] = useState(false)
  const [validate, setValidate] = useState(false)
  const informationData = useMemo<Information[]>(() => JSON.parse(JSON.stringify(sourceData)), [])
  const onCancel = () => {
    setOnlyView(true)
  }
  const onEdit = () => {
    setOnlyView(false)
  }
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      const idx = informationData.findIndex((v) => v.name === name)
      if (!/^[0-9]{0,4}$/.test(value)) {
        return
      }

      informationData[idx]['value'] = value
      setRender((v) => !v)
    },
    [informationData]
  )

  useImperativeHandle(
    informationRef,
    () => {
      return {
        ...informationRef.current,
        pinData: informationData,
      }
    },
    []
  )
  const onClickSave = useCallback(() => {
    let canSave = true
    informationData.forEach((item) => {
      if (item.value && item.value.length !== 4) {
        canSave = false
      }
    })
    if (!canSave) {
      return setValidate(true)
    }
    onSave()
  }, [informationData, onSave])
  useEffect(() => {
    if (isEdit) {
      const data = (editData?.PinCodes || []).reduce((pre, item) => {
        pre[item.Type] = item.Code
        return pre
      }, {} as { [key: string]: string })
      informationData.forEach((v, i) => {
        v.value = data[v.name]
      })
      setRender((v) => !v)
    }
  }, [isEdit, editData])

  return (
    <Box className={`${classes.content} ${classes.information}`}>
      {informationData.map((v, i) => {
        const { name, required, label, value = '' } = v
        const error = (required && validate && !value) || (validate && !!value && value.length !== 4)
        const errorText = value ? '4-digit PIN ' : 'Required'
        return (
          <TextField
            key={name}
            error={error}
            {...v}
            disabled={disabled || onlyView}
            value={value}
            placeholder={label}
            variant="outlined"
            className={classes.textField}
            InputLabelProps={{
              classes: {
                asterisk: classes.asterisk,
              },
            }}
            SelectProps={{
              displayEmpty: true,
              classes: {
                select: classes.select,
              },
              type: 'search',
            }}
            onChange={handleChange}
            helperText={error ? errorText : ''}
          />
        )
      })}

      {new Array(3).fill(true).map((v, i) => (
        <Box key={i} className={`${classes.textField} ${classes.empty}`} />
      ))}
      {!disabled && <EditAndSave showSave={!onlyView} onCancel={onCancel} onEdit={onEdit} onSave={onClickSave} />}
    </Box>
  )
}
export default React.memo(PropertyPIN)
