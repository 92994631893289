import React from 'react'
import { FieldItem } from '@utils/FieldType'
import { LanguageType } from '@activate-inc/activate-ui/dist/types/constant'
import { Button, IconButton, Typography, Autocomplete, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  DragDropContext,
  Draggable,
  DraggableProps,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { AddCircle, ExpandMore, Menu, Help } from '@mui/icons-material'
import CustomTextField from '@component/Form/CustomTextField'
import { reorder } from '@utils/helpers'
import Images from '@/assets/images'

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  background: isDragging ? '#f4f4f4' : 'white',
  ...draggableStyle,
})

const TAG_OPTIONS = ['Scheduled Hours', 'Unplanned Hours', 'Other'].map((v) => ({ label: v, value: v }))

interface Props {
  item: FieldItem
  language: LanguageType
  index: number
  isSingleChoice?: boolean
  disabled?: boolean
  hiddenIcon?: boolean
  onItemChange: (value: string, name: string, index: number, i?: number) => void
  dragHandleProps?: DraggableProps
  onRemove?: (index: number) => void
  onHelp?: () => void
  onChange: (fields: FieldItem) => void
}

const useStyles = makeStyles(() => {
  return {
    choicesItem: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
      color: 'rgba(0,0,0,0.54)',
    },
    choicesItemCenter: {
      flex: 1,
      margin: '0 16px',
    },
    disabled: {
      color: '#BCBCBC',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      padding: '0 16px 16px',
    },
    title: {
      flex: 1,
      fontWeight: 'bold',
      fontSize: 20,
      fontFamily: 'Roboto',
      color: '#4E4D4D',
      display: 'flex',
      alignItems: 'center',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #BCBCBC',
      padding: 24,
      borderRadius: 8,
      marginTop: 20,
    },
    sectionName: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 24,
    },
    itemText: {
      marginTop: 0,
      minWidth: 220,
      flex: 1,
    },
    multiple: {
      minWidth: 220,
      flex: 1,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
    },
    add: {
      alignSelf: 'flex-start',
      padding: 0,
      marginTop: 16,
    },
    moveWrapper: {
      marginTop: 24,
    },
    url: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
    },
    urlLabel: {
      color: '#374053',
      fontSize: 12,
    },
    delete: {
      width: 24,
      height: 24,
    },
  }
})
const MobileEmployeeWorkType: React.FC<Props> = ({
  item,
  index,
  language,
  onItemChange,
  disabled,
  onRemove,
  dragHandleProps,
  onHelp,
  onChange,
}) => {
  const classes = useStyles()
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    const newItems = reorder(item.Choices || [], source.index, destination.index)
    onItemChange(newItems as unknown as string, 'ChoicesDragEnd', index)
  }

  return (
    <div className={classes.section}>
      <Typography className={classes.title}>
        <span style={{ flex: 1 }}>{item.FieldText?.[language] || `Question ${index + 1}`}</span>
        <IconButton disableRipple className={classes.delete} onClick={onHelp}>
          <Help htmlColor="#466CB5" sx={{ fontSize: '24px' }} />
        </IconButton>

        <IconButton disableRipple onClick={() => onRemove?.(index)} disabled sx={{ opacity: 0.5, marginLeft: '8px' }}>
          <img src={Images.iconDelete} alt="delete" />
        </IconButton>
        <div {...dragHandleProps}>
          <IconButton>
            <Menu htmlColor="#466CB5" />
          </IconButton>
        </div>
      </Typography>
      <div className={classes.sectionName}>
        <CustomTextField
          label="Question"
          className={classes.itemText}
          variant="outlined"
          disabled={false}
          name="FieldText"
          onInputChange={(value, name) => onItemChange(value, name, index)}
          value={item.FieldText?.[language]}
          style={{ marginRight: 0 }}
        />
      </div>
      <Typography sx={{ marginTop: '16px' }}>Option</Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {item.Choices?.map((v: any, i: number) => (
                <Draggable key={v?.Label} draggableId={v?.Label} index={i}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      className={classes.item}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                    >
                      <CustomTextField
                        label="Option Name"
                        className={classes.itemText}
                        placeholder="option"
                        value={v.Text?.[language]}
                        variant="outlined"
                        disabled={disabled || false}
                        onInputChange={(value) => {
                          v.Text = {
                            en: value,
                            es: value,
                          }
                          onChange({ ...item })
                        }}
                      />
                      <Autocomplete
                        disableClearable
                        value={TAG_OPTIONS.find((option) => option.value === v.Value)}
                        onChange={(e, value) => {
                          v.Value = value?.value
                          onChange({ ...item })
                        }}
                        options={TAG_OPTIONS}
                        style={{ marginLeft: 16 }}
                        className={classes.multiple}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField {...params} label="Tag" variant="outlined" />}
                        popupIcon={<ExpandMore />}
                      />
                      <IconButton
                        disableRipple
                        disabled={disabled}
                        onClick={() => onItemChange('', 'delete', index, i)}
                        sx={{ marginLeft: '8px', opacity: disabled ? 0.5 : 1 }}
                      >
                        <img src={Images.iconDelete} alt="delete" />
                      </IconButton>
                      <div {...draggableProvided.dragHandleProps}>
                        <IconButton>
                          <Menu htmlColor="#466CB5" />
                        </IconButton>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        className={classes.add}
        style={{ display: item?.Choices.length >= 10 ? 'none' : '' }}
        onClick={() => onItemChange('', 'add', index)}
        disabled={disabled}
      >
        <AddCircle color="primary" style={{ marginRight: 10 }} />
        Add Option
      </Button>
    </div>
  )
}

export default MobileEmployeeWorkType
