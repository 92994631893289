import { Box, Tab, Tabs, Button } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { TabPanel } from '@component/Tabs/TabPanel'
import MultipleSelect from '@component/Filter/MultipleSelect'
import { downloadHtmlPdf, getPropertyCHARFilter } from '@api'
import { Download } from '@mui/icons-material'
import { BuildingITF, PropertyFilterITF, QuestionITF, RegionITF } from '@/types/characteristic'
import PanelSelection from '@component/EditPanel/PanelSelection'
import CONFIG from '@constant/config'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#fff',
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    paddingRight: 24,
  },
  tabSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    background: '#fff',
    borderBottom: '1px solid #BCBCBC',
    marginBottom: 16,
  },
  tabLabel: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontFamily: 'Roboto',
    height: 20,
    lineHeight: 20,
    borderRadius: 4,
    opacity: '1',
  },
  multiple: {
    minWidth: 220,
    marginBottom: 24,
    flex: 1,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const CONFIG_KEY = { label: 'Region', value: 'Region' }
const CONFIG_KEY_BUILDING = { label: 'Name.en', value: 'BuildingId' }
const CONFIG_KEY_ADVANCED = { label: 'FieldText', value: 'Name' }

interface Props {
  gId: string
  onSearch?: (params: Record<string, string>, type: number) => void
}
const PropertyCharacteristcFilter: React.FC<Props> = ({ gId, onSearch }) => {
  const classes = useStyles()
  const [tabId, setTabId] = useState(0)
  const [downloading, setDownloading] = useState(false)
  const [region, setRegion] = useState<RegionITF>()
  const [selectRegion, setSelectRegion] = useState<RegionITF>()
  const [building, setBuilding] = useState<BuildingITF | null>()
  const [filter, setFilter] = useState<PropertyFilterITF>()
  const [advanced, setAdvanced] = useState<{ [key: string]: QuestionITF[] }>({})
  const { buildings } = useMemo(() => {
    return {
      buildings: selectRegion
        ? (filter?.Buildings || []).filter((item) => item.Region === selectRegion.Region)
        : filter?.Buildings || [],
    }
  }, [selectRegion, filter])
  const getData = useCallback(() => {
    getPropertyCHARFilter<PropertyFilterITF>({ gId }).then((res) => {
      setFilter(res)
    })
  }, [gId])
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabId(newValue)
    if (tabId) {
      setAdvanced({})
    } else {
      setRegion(undefined)
      setBuilding(null)
    }
  }
  const onChangeRegion = (value) => {
    setRegion(value)
    setSelectRegion(value)
    if (building && building.Region !== value?.Region) {
      setBuilding(null)
    }
  }
  const onChangeAdvanced = (value, key) => {
    Object.assign(advanced, { [key]: value })
    setAdvanced(advanced)
  }
  const onDownLoad = () => {
    setDownloading(true)
    downloadHtmlPdf<{ FileUrl: string }>({
      Url: `${CONFIG.adminHost}/pdfCharacteristics/${gId}/${building?.BuildingId}`,
      FileName: `${building?.Name?.en}-${building?.BuildingId}.pdf`,
    })
      .then((res) => {
        if (!res?.FileUrl) return
        window.open(res?.FileUrl, '_blank')
      })
      .finally(() => {
        setDownloading(false)
      })
  }
  const onChangeBuilding = (value) => {
    setBuilding(value)
    !value && setSelectRegion(undefined)
    setRegion(filter?.Regions?.find((item) => item.Region === value?.Region))
  }
  const onClickSearch = (type: number) => {
    const params = {}
    if (type === 0) {
      Object.assign(params, { bId: building?.BuildingId })
    } else {
      const advanceds: Array<string> = []
      advanced &&
        Object.values(advanced).forEach((item) => {
          item.forEach((item1) => {
            advanceds.push(item1.Name)
          })
        })
      Object.assign(params, { fields: JSON.stringify(advanceds) })
    }
    onSearch?.(params, tabId)
  }

  useEffect(() => {
    getData()
  }, [getData])
  return (
    <Box className={classes.container}>
      <Tabs value={tabId} onChange={handleTabChange} className={classes.tabSection}>
        <Tab label="Search By Building" value={0} className={classes.tabLabel} />
        <Tab label="Advanced Search" value={1} className={classes.tabLabel} />
      </Tabs>
      <TabPanel value={tabId} index={0} sx={{ textAlign: 'end', padding: 0 }}>
        <PanelSelection
          // @ts-ignore
          choices={filter?.Regions || []}
          label="Region"
          height={56}
          width={'100%'}
          // @ts-ignore
          value={region}
          disableClearable={false}
          // defaultValue={item.Buttons}
          isOptionEqualToValue={(option, value) => option?.Region === value?.Region}
          getOptionLabel={(option) => option.Region || ''}
          onChange={onChangeRegion}
          className={classes.multiple}
        />
        <PanelSelection
          // @ts-ignore
          choices={buildings}
          label="Building Name"
          disableClearable={false}
          height={56}
          width={'100%'}
          // @ts-ignore
          value={building}
          // defaultValue={item.Buttons}
          isOptionEqualToValue={(option, value) => option?.BuildingId === value?.BuildingId}
          getOptionLabel={(option) => option.Name?.en || ''}
          onChange={onChangeBuilding}
          className={classes.multiple}
        />
        <div className={classes.buttons}>
          <Button disabled={!building} variant="outlined" sx={{ marginTop: '8px' }} onClick={onDownLoad}>
            {downloading && building ? <CircularProgress size={24} /> : <Download sx={{ marginRight: '6px' }} />}
            Download
          </Button>
          <Button
            disabled={!building}
            variant="contained"
            sx={{ marginTop: '8px' }}
            onClick={() => {
              onClickSearch(0)
            }}
          >
            Search
          </Button>
        </div>
      </TabPanel>
      <TabPanel value={tabId} index={1} sx={{ textAlign: 'end', padding: 0 }}>
        {filter?.Questions?.map((item) => {
          return (
            <MultipleSelect
              key={item.SectionName}
              isMulti
              // @ts-ignore
              data={item?.Questions || []}
              label={item.SectionName}
              itemKey={CONFIG_KEY_ADVANCED}
              onChange={(value) => onChangeAdvanced(value, item.SectionName)}
              className={classes.multiple}
            />
          )
        })}
        <Button
          variant="contained"
          sx={{ marginTop: '8px' }}
          onClick={() => {
            onClickSearch(1)
          }}
        >
          Search
        </Button>
      </TabPanel>
    </Box>
  )
}
export default PropertyCharacteristcFilter
