import React, { useCallback, useEffect, useState } from 'react'
import { getRoundExceptionReport } from '@api'
import { RoundSummary as RoundSummaryITF } from '@/types/analytics'
import PhotoTip from '@component/Round/PhotoTip'
import TableList, { Order, TableConfig } from '@component/Tabs/TableList'
import useSetState from '@hooks/useSetState'
import { DEFAULT_PERIOD_LAST_YEAR } from '@/global'
import get from 'lodash/get'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'

interface Props {
  gId: string
  bId: string
  et?: string
  range?: DateRange<Dayjs>
  emptyText?: string
  config?: Array<TableConfig<RoundSummaryITF>>
  rType: string
  ptId?: string
}

const CONFIG: Array<TableConfig<RoundSummaryITF>> = [
  {
    label: 'Time',
    formatKey: 'keyboardDateTime',
    source: 'SubmitDate',
    isDate: true,
    width: 200,
  },
  {
    label: 'Round',
    source: 'RoundName',
    width: 189,
  },
  {
    label: 'Space',
    source: 'MechanicalSpace',
    width: 171,
  },
  {
    label: 'Equipment',
    source: 'EquipmentName',
    width: 120,
  },
  {
    label: 'Error Reason',
    source: 'ErrorReason',
    width: 140,
  },
  {
    label: 'Question',
    source: 'Question',
    width: 220,
  },
  {
    label: 'Error',
    source: 'ErrorReport',
    width: 220,
  },
  {
    label: 'Comments',
    source: 'Comments',
    width: 130,
  },
  {
    label: 'Engineer Name',
    source: 'InspectorName',
    width: 150,
  },
  {
    label: 'Photo',
    source: 'StopsErrorNumber',
    width: 140,
    isPuppeteer: true,
    convertItem: (item) => <PhotoTip photo={item.PhotoUrl} reason={item.ErrorReason} />,
  },
]

const RoundExceptions: React.FC<Props> = ({ gId, bId, range, et, config, emptyText, rType, ptId }) => {
  const [data, setData] = useState<Array<RoundSummaryITF>>()
  const [{ order, orderBy }, setState] = useSetState({
    order: 'desc' as Order,
    orderBy: '',
  })
  const getData = useCallback(async () => {
    setData([])
    const params = { gId, bId, et, rt: rType, ptId }
    if (range?.length === 2 && range[0]?.isValid() && range[1]?.isValid()) {
      Object.assign(params, { startDate: range[0].valueOf(), endDate: range[1].valueOf() })
    }
    const result = await getRoundExceptionReport<Array<RoundSummaryITF>>(params)
    setData(result)
  }, [gId, range, bId, et, rType, ptId])
  const onSort = useCallback(
    (orderBy, order) => {
      if (!data) return
      const result = data.sort((item, item1) => {
        const text1 = (get(item, orderBy) || '') + ''
        const text2 = (get(item1, orderBy) || '') + ''
        if (order === 'asc') return text2.localeCompare(text1)
        return text1.localeCompare(text2)
      })
      setState({ orderBy, order })
      setData(result)
    },
    [data, setState]
  )
  useEffect(() => {
    getData()
  }, [getData])

  return (
    <TableList
      tableLayout="fixed"
      config={config || CONFIG}
      data={data}
      onSort={onSort}
      orderBy={orderBy}
      order={order}
      emptyText={emptyText}
      rowKey={(item, index) => item.id}
    />
  )
}

export default RoundExceptions
